import { redirect } from 'react-router-dom';
import ProjectHomeRoute from '@/routes/ProjectHomeRoute';
import { getProjects, removeMember } from '@/api/projects';

import { deletePathway, getPathway, getPathwayParams } from '@/api/pathways';
import { deleteSystem, getSystem } from '@/api/systems';
import EditPathwayModal from '@/components/pathways/EditPathwayModal';
import EditSystemModal from '@/components/systems/EditSystemModal';
import AddMemberModal from '@/components/members/AddMemberModal';

const dashboardRouter = {
  path: 'dashboard',
  element: <ProjectHomeRoute />,
  handle: {
    crumb: () => 'Dashboard',
  },
  children: [
    {
      path: 'pathways/new',
      element: <EditPathwayModal title="New Pathway" />,
      action: EditPathwayModal.addAction,
    },
    {
      path: 'pathways/:pathwayId/edit',
      element: <EditPathwayModal title="Edit Pathway" />,
      loader: async ({ params: { pathwayId } }) => {
        const [
          { data: pathway },
          {
            data: { params },
          },
        ] = await Promise.all([getPathway(pathwayId), getPathwayParams(pathwayId)]);

        return { pathway, params };
      },
      action: EditPathwayModal.editAction,
    },
    {
      path: 'pathways/:pathwayId/destroy',
      action: async ({ params }) => {
        const { projectId, pathwayId } = params;
        try {
          await deletePathway(pathwayId);
          return redirect(`/projects/${projectId}/dashboard`);
        } catch (e) {
          return redirect(`/projects/${projectId}/dashboard`);
        }
      },
    },
    {
      path: 'pathways/:pathwayId/duplicate',
      element: <EditPathwayModal title="Duplicate Pathway" />,
      loader: async ({ params: { pathwayId } }) => {
        const [
          { data: pathway },
          {
            data: { results: projects },
          },
        ] = await Promise.all([getPathway(pathwayId), getProjects()]);

        return { pathway, projects };
      },
      action: EditPathwayModal.duplicateAction,
    },
    {
      path: 'systems/new',
      element: <EditSystemModal action="add" title="New System" />,
      action: EditSystemModal.addAction,
    },
    {
      path: 'systems/:systemId/edit',
      element: <EditSystemModal action="edit" title="Rename System" />,
      loader: async ({ params: { systemId } }) => {
        const { data: system } = await getSystem(systemId);
        return { system };
      },
      action: EditSystemModal.editAction,
    },
    {
      path: 'systems/:systemId/destroy',
      action: async ({ params }) => {
        const { projectId, systemId } = params;
        await deleteSystem(systemId);
        return redirect(`/projects/${projectId}`);
      },
    },
    {
      path: 'systems/:systemId/duplicate',
      element: <EditSystemModal action="duplicate" title="Duplicate System" />,
      loader: async ({ params: { systemId } }) => {
        const [
          { data: system },
          {
            data: { results: projects },
          },
        ] = await Promise.all([getSystem(systemId), getProjects()]);

        return { projects, system };
      },
      action: EditSystemModal.duplicateAction,
    },
    {
      path: 'new-member',
      action: AddMemberModal.action,
      element: <AddMemberModal title="Add Member" />,
    },
    {
      path: 'edit-member',
      action: AddMemberModal.action,
      element: <AddMemberModal title="Edit Member" />,
    },
    {
      path: 'remove-member',
      action: async ({ request, params }) => {
        const form = await request.formData();
        const member = Object.fromEntries(form);
        const { projectId } = params;

        await removeMember(projectId, member);

        if (member.self) {
          return redirect('/');
        }

        return redirect(`/projects/${projectId}`);
      },
    },
  ],
};

export default dashboardRouter;
