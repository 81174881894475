import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import usePatchParams from '../usePatchParams';
import { useParamsContext } from '@/components/providers/useParamsContext';

const ToggleField = ({ paramSchema, param, name }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { patchPrimary } = usePatchParams();
  const { label, tooltip, source } = paramSchema;
  const error = get(errors, name);
  const errorMessage = error?.message?.primary?.value;
  const { primary: { value } = {} } = param ?? {};
  const { readOnly } = useParamsContext();

  return (
    <FormRow label={label} name={name} tooltip={tooltip} source={source}>
      <Controller
        control={control}
        name={name}
        defaultValue={value}
        render={({ field: { value, onChange } }) => (
          <>
            <input
              type="checkbox"
              disabled={readOnly}
              readOnly={readOnly}
              id={name}
              name={name}
              className={`toggle mt-1 toggle-success ${errorMessage ? 'input-error' : ''}`}
              onChange={e => {
                const value = e.target.checked ? true : false;
                onChange(value);
                patchPrimary(name, value);
              }}
              checked={value === true}
            />
            <ErrorMessage text={errorMessage} />
          </>
        )}
      />
    </FormRow>
  );
};

ToggleField.propTypes = {
  param: PropTypes.object,
  paramSchema: PropTypes.object,
  name: PropTypes.string,
};

export default ToggleField;
