import AnalysisContainer from '../../core/AnalysisContainer';
import AnalysisBox from '../../core/AnalysisBox';
import LCAWorkbenchPlot from './lca/LCAWorkbenchPlot';
import TEAWorkbenchPlot from './tea/TEAWorkbenchPlot';
import WorkbenchPlotForm from './WorkbenchPlotForm';
import { workbenchActions, useTEAPlot, useLCAPlot } from '@/stores/workbenchStore';

export const WorkbenchPlotsSection = () => {
  const teaPlot = useTEAPlot();
  const lcaPlot = useLCAPlot();
  const { node, results: teaResults } = teaPlot;
  const { results: lcaResults } = lcaPlot;
  const updatePlot = formData => {
    workbenchActions.updatePlot('lca', formData);
    workbenchActions.updatePlot('tea', formData);
  };

  if (!teaResults && !lcaResults) {
    return null;
  }

  return (
    <AnalysisContainer
      title={
        <div className="grid grid-cols-2 gap-4">
          {lcaResults && <div>Life Cycle Analysis</div>}
          {teaResults && <div>Techno-Economic Analysis</div>}
        </div>
      }
    >
      <AnalysisBox>
        <div className="grid grid-cols-2 gap-4">
          <LCAWorkbenchPlot />
          <TEAWorkbenchPlot />
        </div>
        <div className="grid grid-cols-2 gap-4">
          {node && <WorkbenchPlotForm onSubmit={updatePlot} defaultValues={teaPlot} />}
        </div>
      </AnalysisBox>
    </AnalysisContainer>
  );
};
