import PropTypes from 'prop-types';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

const FileTemplate = ({ url }) => {
  return (
    <div className="flex-1 bg-gray-100 rounded-lg p-3 flex items-center justify-center">
      <a
        href={url}
        target="_parent"
        download
        className="flex items-center gap-3 text-gray-600 hover:text-gray-800"
        rel="noreferrer"
      >
        <ArrowDownTrayIcon className="w-6 h-6" />
        <div className="text-left">
          <div className="text-sm font-medium">File Template</div>
          <div className="text-xs text-center text-gray-400">CSV</div>
        </div>
      </a>
    </div>
  );
};

FileTemplate.propTypes = {
  url: PropTypes.string.isRequired,
};

export default FileTemplate;
