import PropTypes from 'prop-types';
import FormRow from '@/components/core/form/FormRow';
import { useFormContext } from 'react-hook-form';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import usePatchParams from '../usePatchParams';
import { useEffect, useState } from 'react';
import FileBase from '@/components/core/form/FileBase';

const FileField = ({ param, paramSchema, name }) => {
  const { label, tooltip, source, editable, template_url: templateUrl } = paramSchema;
  const [fileInfo, setFileInfo] = useState(null);
  const { filename, identifier, url, size } = param?.primary?.value ?? {};

  const {
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);
  const errorMessage = error?.message?.primary?.value;
  const { uploadFile } = usePatchParams();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (filename && identifier) {
      setFileInfo({
        filename,
        identifier,
        type: 'csv',
        size,
        url,
      });
    }
  }, [filename, size, url, identifier]);

  const handleUpload = async file => {
    if (!file) return;

    try {
      setIsUploading(true);
      await uploadFile(name, 'primary', { filename: file.name }, file);
    } catch (error) {
      console.error('File upload failed:', error);
      setFileInfo(null);
    } finally {
      setIsUploading(false);
    }
  };

  const handleDelete = async () => {
    if (!editable) return;
    await uploadFile(name, 'primary', null, null);
    setFileInfo(null);
  };

  return (
    <FormRow label={label} tooltip={tooltip} source={source} name={name}>
      <div className="flex flex-col flex-1">
        <FileBase
          name={name}
          editable={editable}
          onUpload={handleUpload}
          onDelete={handleDelete}
          label="a custom capacity factor profile"
          acceptedFormats="csv"
          className="text-xs"
          isError={!!errorMessage}
          isUploading={isUploading}
          fileInfo={fileInfo}
          templateUrl={templateUrl}
        />
        <ErrorMessage text={errorMessage} />
      </div>
    </FormRow>
  );
};

FileField.propTypes = {
  paramSchema: PropTypes.object,
  param: PropTypes.object,
  name: PropTypes.string,
};

export default FileField;
