import { capitalizeFirstLetter, isDefined } from './miscUtils';
import Decimal from 'decimal.js';

Decimal.set({ precision: 15, rounding: 8 });

const conversions = {
  CtoF: value => value * 1.8 + 32,
  FtoC: value => (value - 32) / 1.8,
  KtoC: value => value - 273.15,
  CtoK: value => value + 273.15,
  KtoF: value => conversions.CtoF(conversions.KtoC(value)),
  FtoK: value => conversions.CtoK(conversions.FtoC(value)),
};

const unitMap = {
  C: 'C',
  F: 'F',
  Kelvin: 'K',
};

const isTemperature = conversion => conversion.unit?.numerator?.[0]?.name?.match(/^(Kelvin|C|F)$/i);

export const convertTemperature = (from, to, value) => {
  const fromUnit = unitMap[from.unit.numerator[0].name];
  const toUnit = unitMap[to.unit.numerator[0].name];

  if (fromUnit === toUnit) {
    return value;
  }

  return new Decimal(conversions?.[`${fromUnit}to${toUnit}`]?.(value)).toDecimalPlaces(1).toNumber();
};

export const numberFormat = (value, digits = 2, useGrouping = true) => {
  try {
    return new Intl.NumberFormat('en', {
      maximumFractionDigits: digits,
      minimumFractionDigits: 0,
      useGrouping,
    }).format(value);
  } catch (error) {
    console.error('Number formatting error:', error);
    return value;
  }
};

export const convert = (from, to, value) => {
  const temperatureUnit = isTemperature(to);

  if (temperatureUnit) {
    return convertTemperature(from, to, value);
  }

  const dValue = new Decimal(value);
  const dFromFactor = new Decimal(from.factor);
  const dToFactor = new Decimal(to.factor);

  return dValue.dividedBy(dFromFactor).times(dToFactor).toNumber();
};

export const convertFlowRate = (from = 1, to = 1, value) => {
  const dValue = new Decimal(value);
  const dFromFactor = new Decimal(from);
  const dToFactor = new Decimal(to);

  return dValue.dividedBy(dToFactor).times(dFromFactor).toNumber();
};

const parseNameAndSpecies = ({ name }) => {
  let parsed = name;

  if (parsed === 'dollar') return '$';

  // if (species) {
  //   parsed += `[${species}]`;
  // }

  return parsed;
};

export const unitToString = unit => {
  if (!unit) {
    return '';
  }

  if (isDefined(unit.label)) {
    return unit.label;
  }

  const numerators = unit?.numerator.map(parseNameAndSpecies).join('*');
  const denominators = unit?.denominator.map(parseNameAndSpecies).join('*');

  return `${numerators}${denominators ? '/' : ''}${denominators}`;
};

export const getCostsOptions = conversions => {
  return conversions?.map(conversion => ({
    value: JSON.stringify({
      numerator: [{ name: 'dollar' }],
      denominator: [{ name: conversion?.unit?.numerator[0]?.name }],
    }),
    label: `$/${unitToString(conversion?.unit)}`,
  }));
};

export const getConversionOptions = conversions => {
  return conversions?.map(conversion => ({
    value: JSON.stringify(conversion),
    label: unitToString(conversion?.unit),
  }));
};

export const getUnitOptions = units => {
  return units?.map(unit => ({
    value: JSON.stringify(unit),
    label: unitToString(unit),
  }));
};

export const getMeasures = conversionsByMeasures => {
  return Object.keys(conversionsByMeasures).map(value => ({
    value,
    label: capitalizeFirstLetter(value),
  }));
};

export const findConversionByUnit = (conversions, unit) => {
  const numerator = JSON.stringify(unit.numerator);
  const denominator = JSON.stringify(unit.denominator);

  return conversions.find(
    ({ unit }) => JSON.stringify(unit.denominator) === denominator && JSON.stringify(unit.numerator) === numerator,
  );
};
