import PropTypes from 'prop-types';
import { unitToString } from '@/utils/unitUtils';
import { numberFormat } from '@/utils/unitUtils';

const CostOverrideValue = ({ costOverride }) => {
  const { cost, unit } = costOverride;
  return (
    <div className="p-1 pl-6 pr-6 text-gray-500/80">
      User Cost: {numberFormat(cost)} {unitToString(unit)}
    </div>
  );
};

CostOverrideValue.propTypes = {
  costOverride: PropTypes.object,
};

export default CostOverrideValue;
