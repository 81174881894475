import { duplicateCase } from '@/api/cases';
import { createPathway, patchPathway } from '@/api/pathways';
import { isBuildPath } from '@/utils/miscUtils';
import PropTypes from 'prop-types';
import {
  Link,
  useLoaderData,
  useActionData,
  useParams,
  redirect,
  json,
  useSubmit,
  useRouteLoaderData,
} from 'react-router-dom';
import SubmitButton from '../core/form/SubmitButton';
import { FormProvider, get, useForm } from 'react-hook-form';
import Select from '../core/form/Select';
import Tooltip from '../core/Tooltip';
import InputBase from '../core/form/InputBase';
import LocationBase from '../core/form/LocationBase';

const paramTypes = {
  numeric: InputBase,
  location: LocationBase,
};

const paramTypeConfig = {
  numeric: (name, param, register) => ({
    ...register(name, {
      valueAsNumber: true,
      required: `${param.label} is required`,
    }),
  }),
  location: (name, param) => ({
    name,
    placeholder: param.placeholder,
  }),
};

const defaultValues = {
  analysis_start_year: new Date().getFullYear(),
};

const EditPathwayModal = ({ title }) => {
  const { pathway, projects, params } = useLoaderData() ?? {};
  const { pathwayParams } = useRouteLoaderData('root');
  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const submit = useSubmit();
  const actionErrors = useActionData();
  const name = pathway?.name ?? '';
  const caseId = pathway?.case_id ?? '';
  const { projectId } = useParams();
  const projectOptions = projects?.map(({ id, name }) => ({ label: name, value: id }));
  const defaultName = projects?.length ? `${name} [duplicate]` : name;

  const onSubmit = (data, event) => {
    event.preventDefault();
    submit(data, {
      method: 'put',
      encType: 'application/json',
    });
  };

  const renderParam = (paramKey, param) => {
    const ParamComponent = paramTypes[param.type];
    if (!ParamComponent) return null;

    const defaultValue =
      params?.[paramKey]?.primary?.value ?? params?.[paramKey]?.primary?.default ?? defaultValues[paramKey];
    const fieldName = `params.${paramKey}.primary.value`;
    const config = paramTypeConfig[param.type]?.(fieldName, param, register) ?? {};
    const error = get(errors, fieldName);

    return (
      <label key={paramKey} className="form-control w-full">
        <div className="label">
          <div className="flex items-center gap-2">
            <span className="label-text">{param.label}</span>
            <Tooltip label={param.label} text={param.tooltip} source={param.source} />
          </div>
        </div>
        <ParamComponent
          {...config}
          className="input input-bordered input-sm w-full"
          hasError={!!error}
          defaultValue={defaultValue}
          value={param.type === 'location' ? defaultValue : undefined}
        />
        <div className="label">
          <span className="label-text-alt text-error">{error?.message}</span>
        </div>
      </label>
    );
  };

  return (
    <FormProvider {...methods}>
      <dialog className="modal" open>
        <div className="modal-box">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="font-bold text-lg">{title}</h3>
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">Name</span>
              </div>
              <input
                type="text"
                {...register('name')}
                placeholder="Enter pathway name"
                className="input input-bordered input-sm w-full"
                defaultValue={defaultName}
              />
              <div className="label">
                <span className="label-text-alt text-error">{actionErrors?.name?.[0]}</span>
              </div>
              <input type="hidden" {...register('curName')} defaultValue={name} />
              <input type="hidden" {...register('caseId')} defaultValue={caseId} />
            </label>
            {!projectOptions?.length && (
              <>{Object.entries(pathwayParams).map(([key, param]) => renderParam(key, param))}</>
            )}
            {projectOptions?.length && (
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">Project</span>
                </div>
                <Select
                  className="w-full select-sm"
                  name="project_id"
                  defaultValue={projectId}
                  options={projectOptions}
                />
              </label>
            )}
            <div className="modal-action">
              <Link className="btn btn-sm btn-secondary" to={-1} replace>
                Cancel
              </Link>
              <SubmitButton label="Save" />
            </div>
          </form>
        </div>
      </dialog>
    </FormProvider>
  );
};

EditPathwayModal.propTypes = {
  title: PropTypes.string,
};

EditPathwayModal.addAction = async ({ request, params }) => {
  const { projectId } = params;
  const pathway = await request.json();
  const buildPath = isBuildPath(request.url);
  const actionPath = buildPath ? `/projects/${projectId}/build/pathways` : `/projects/${projectId}/dashboard`;

  try {
    const { data } = await createPathway(projectId, pathway);
    return redirect(buildPath ? `${actionPath}/${data.id}` : actionPath);
  } catch (error) {
    return json(error.response.data);
  }
};

EditPathwayModal.editAction = async ({ request, params }) => {
  const { projectId, pathwayId } = params;
  const pathway = await request.json();
  const matchBuild = isBuildPath(request.url);
  const actionPath = matchBuild ? `/projects/${projectId}/build` : `/projects/${projectId}/dashboard`;

  try {
    await patchPathway(pathwayId, pathway);
    return redirect(matchBuild ? `${actionPath}/pathways/${pathwayId}` : actionPath);
  } catch (error) {
    return json(error.response.data);
  }
};

EditPathwayModal.duplicateAction = async ({ request, params }) => {
  const { projectId, pathwayId } = params;
  const pathway = await request.json();
  const { name, curName, caseId, project_id } = pathway;
  const matchBuild = isBuildPath(request.url);
  const actionPath = matchBuild ? `/projects/${projectId}/build` : `/projects/${projectId}/dashboard`;

  if (name === curName) {
    return json({ name: 'Please choose different name' });
  }

  try {
    const { data } = await duplicateCase(caseId, { name, project_id });
    return redirect(matchBuild ? `${actionPath}/pathways/${data.pathway_id}` : actionPath);
  } catch (err) {
    return redirect(
      matchBuild ? `${actionPath}/pathways/${pathwayId}/duplicate` : `${actionPath}/${pathwayId}/duplicate`,
    );
  }
};

export default EditPathwayModal;
