import { numberFormat } from '@/utils/unitUtils';
import PropTypes from 'prop-types';

const MCFieldsSummary = ({ min, max }) => (
  <div className={`flex gap-4 text-xs ml-2 items-center`}>
    <div className="flex gap-1 items-center">
      <span className="badge badge-success h-2 px-[0.2rem]"></span>
      <span className="text-gray-400">Uncertainty</span>
    </div>
    <div className="flex gap-1">
      <span className="text-gray-400">Min:</span>
      {numberFormat(min)}
    </div>
    <div className="flex gap-1">
      <span className="text-gray-400">Max:</span>
      {numberFormat(max)}
    </div>
  </div>
);

MCFieldsSummary.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
};

export default MCFieldsSummary;
