import { useNavigate } from 'react-router-dom';
import FileBase from '../core/form/FileBase';
import { useForm, Controller } from 'react-hook-form';
import { DocumentIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { createExcelModel, getRegistry } from '../../api/registry';
import { useParams } from 'react-router-dom';
import { registryActions } from '@/stores/registryStore';
import SubmitButton from '../core/form/SubmitButton';

const ImportModelModal = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [fileInfo, setFileInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const params = useParams();
  const navigate = useNavigate();

  const handleClose = () => {
    const pathwayId = params.pathwayId;
    const projectId = params.projectId;

    navigate(`/projects/${projectId}/build/pathways/${pathwayId}`, { replace: true });
  };

  const handleFile = async file => {
    if (!file) return;

    try {
      setErrorMessage('');
      setFileInfo({
        filename: file.name,
        size: file.size,
        type: 'xlsx',
      });
    } catch (error) {
      setFileInfo(null);
    }
  };

  const handleDelete = () => {
    setFileInfo(null);
    setErrorMessage('');
  };

  const onSubmit = async data => {
    try {
      setIsSubmitting(true);
      setSuccessMessage('');
      setErrorMessage('');
      const formData = new FormData();

      formData.append('name', data.name);
      formData.append('file', data.file);

      await createExcelModel(formData);

      const {
        data: { node_types: nodeTypes },
      } = await getRegistry();

      registryActions.init(nodeTypes);

      setSuccessMessage('File has been converted into a Sesame module.');
    } catch (error) {
      const { data } = error?.response ?? {};
      const message = data?.error ?? 'Unknown error';
      setErrorMessage(message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <dialog className="modal" open>
      <div className="modal-box w-4/5 max-w-5xl p-8">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={handleClose}>
          ✕
        </button>

        <h3 className="font-bold text-lg text-center mb-4">Upload a Model</h3>

        <p className="text-sm text-center mb-8">
          Sesame allows users to create their own module by importing a custom Excel spreadsheet. Two sheets should be
          added to your spreadsheet and formatted according to our guidelines. Download the sheets template below and
          integrate them into your model following the guidelines outlined in the dropdown.
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="flex flex-col items-center">
            <label className="block text-sm font-medium mb-2 w-3/4">Module Name</label>
            <input
              type="text"
              className={`input input-bordered w-3/4 input-md ${errors.name ? 'input-error' : ''}`}
              placeholder="Enter Module Name"
              {...register('name', { required: 'Module name is required' })}
            />
            {errors.name && <p className="text-error text-sm mt-1">{errors.name?.message?.toString()}</p>}
          </div>

          <div className="flex flex-col">
            <Controller
              name="file"
              control={control}
              rules={{ required: 'Please upload a model file' }}
              render={({ field }) => (
                <FileBase
                  name="file"
                  className="text-md"
                  onUpload={file => {
                    handleFile(file);
                    field.onChange(file);
                  }}
                  onDelete={() => {
                    handleDelete();
                    field.onChange(null);
                  }}
                  acceptedFormats="xlsx"
                  label="a custom model"
                  fileInfo={fileInfo}
                  isError={!!errors.file}
                  isUploading={isSubmitting}
                />
              )}
            />
            {errors.file && <p className="text-error text-sm mt-1">{errors.file?.message}</p>}
          </div>

          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <div className="text-gray-600">
                <DocumentIcon className="w-5 h-5" />
              </div>
              <div>
                <div className="font-medium text-sm">File Template</div>
                <div className="text-xs text-gray-500">XLSX</div>
              </div>
            </div>

            <a
              href="https://docs.sesame.software/data_upload/excel_upload_template.xlsx"
              target="_parent"
              download
              className="btn btn-sm gap-2 bg-white shadow-sm hover:shadow rounded-lg border border-gray-200 text-gray-600"
              rel="noreferrer"
            >
              <ArrowDownTrayIcon className="w-4 h-4" />
              Download
            </a>
          </div>

          <details className="collapse collapse-arrow border border-gray-200 rounded-lg">
            <summary className="collapse-title font-medium">
              How to import your own model and create a custom Sesame module
            </summary>
            <div className="collapse-content">
              <ul className="list-decimal space-y-2 pl-4">
                <li>Download the template Excel file</li>
                <li>Add the sheets SESAME-PARAMS and SESAME-O to your Excel file</li>
                <li>
                  Fill in the sheets to connect to your model. The full guidelines are available{' '}
                  <a
                    href="https://docs.sesame.software/pathways/modules/adding-a-custom-module.html"
                    target="_blank"
                    rel="noreferrer"
                    className="text-purple-500 underline hover:text-purple-400"
                  >
                    in the documentation
                  </a>
                </li>
                <li>Upload your Excel model</li>
                <li>
                  Your Excel will be converted into a Sesame module, which you can find under the &quot;Custom&ldquo;
                  tab in the palette
                </li>
              </ul>
            </div>
          </details>

          {errorMessage && (
            <div className="bg-red-50 border border-red-200 rounded-lg p-4">
              <p className="font-medium text-red-700 text-sm mb-2">
                File is not convertible into a Sesame module for the following reason:
              </p>
              <ul className="list-disc list-inside text-red-700 text-sm space-y-1">
                <li>{errorMessage}</li>
              </ul>
              <p className="text-red-700 text-sm mt-2">Please correct this issue and re-upload the file.</p>
            </div>
          )}

          {successMessage && (
            <div className="bg-green-50 border border-green-200 rounded-lg p-4">
              <p className="font-medium text-green-700 text-sm">{successMessage}</p>
            </div>
          )}

          <div className="flex justify-center mt-6">
            {!successMessage && <SubmitButton label="Convert" isPending={isSubmitting} isDisabled={!isValid} />}
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default ImportModelModal;
