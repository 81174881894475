import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import ErrorMessage from './ErrorMessage';
import ParameterFields from '../ParameterFields';
import { PARAM_TYPES_WITH_DEPENDENCIES } from '@/consts';
import usePatchParams from '../usePatchParams';
import DerivedFields from './DerivedFields';

const BlockField = ({ paramSchema, param }) => {
  const {
    formState: { errors },
  } = useFormContext();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { name, params } = paramSchema;
  const dependencyParams = params.filter(({ type }) => PARAM_TYPES_WITH_DEPENDENCIES.includes(type));
  const errorMessage = errors?.[name]?.root?.message?.primary?.value;
  const { addBlock, removeBlock } = usePatchParams();
  const paramValues = param.params[selectedIndex] ?? {};

  const handleAddBlock = event => {
    event.preventDefault();
    event.stopPropagation();

    const index = Object.values(param.params).length || 0;
    addBlock(name, index);
    setSelectedIndex(index);
  };

  const handleRemoveBlock = (event, index) => {
    event.preventDefault();
    event.stopPropagation();

    removeBlock(name, index);

    const size = Object.values(param.params).length || 0;

    if (size >= 1) {
      setSelectedIndex(0);
    }
  };

  return (
    <div className="flex mt-6 gap-6 min-h-0">
      <div className="w-1/2 overflow-scroll">
        <div className="mb-4 flex items-center gap-2">
          <select
            className="select bg-gray-50 border-0 w-full select-sm focus:outline-none"
            value={selectedIndex}
            onChange={e => setSelectedIndex(Number(e.target.value))}
          >
            {Object.values(param.params).map((field, index) => (
              <option key={`${name}-${index}`} value={index}>
                {field?.label?.primary?.value || `${name} ${index + 1}`}
              </option>
            ))}
          </select>
          <button onClick={handleAddBlock} className="btn btn-neutral bg-gray-50 border-0 btn-sm mr-2 rounded-md">
            <PlusIcon className="h-5 w-5" />
          </button>
        </div>

        {Object.values(param.params).length > 0 && (
          <div className="pl-2 pr-2 mb-4">
            <ParameterFields index={selectedIndex} paramSchemas={params} parentName={name} paramValues={paramValues} />
            <div className="flex justify-center mt-2">
              <button onClick={e => handleRemoveBlock(e, selectedIndex)} className="btn btn-sm btn-circle btn-ghost">
                <TrashIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        )}

        <div className="col-span-2 flex justify-center">
          <ErrorMessage text={errorMessage} />
        </div>
      </div>
      <div className="w-1/2">
        {dependencyParams?.length > 0 && (
          <DerivedFields
            paramValues={paramValues}
            paramSchemas={dependencyParams}
            index={selectedIndex}
            parentName={name}
          />
        )}
      </div>
    </div>
  );
};

BlockField.propTypes = {
  param: PropTypes.object,
  paramSchema: PropTypes.object,
};

export default BlockField;
