import LineChart from '@/components/charts/LineChart';
import { useTEAPlot } from '@/stores/workbenchStore';
import { getTEAYaxisLabel } from '@/utils/analysisUtils';

const TEAWorkbenchPlot = () => {
  const plot = useTEAPlot();
  const { node, paramLabels, results, workbenchUnits } = plot;

  if (!results) {
    return;
  }

  const legendTitle = paramLabels[paramLabels.length - 1];
  const legendUnit = workbenchUnits[workbenchUnits.length - 1];

  return (
    <LineChart
      results={results}
      label={`${paramLabels[0]} - ${node.label}`}
      legendTitle={`${legendTitle} (${legendUnit})`}
      yAxisLabel={getTEAYaxisLabel(plot, node)}
    />
  );
};

export default TEAWorkbenchPlot;
