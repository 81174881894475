import { Position, MarkerType } from '@xyflow/react';
import ELK from 'elkjs/lib/elk.bundled.js';
import { keyBy } from './miscUtils';
import { unitToString } from './unitUtils';
import {
  ANALYSIS_STATUS,
  CO2_EQUIVALENT,
  CO2_EQUIVALENT_EMISSION_TYPE,
  NODE_TYPES,
  SYSTEM_NAMES_TO_TYPES,
} from '@/consts';

// elk layouting options can be found here:
// https://www.eclipse.org/elk/reference/algorithms/org-eclipse-elk-layered.html
const layoutOptions = {
  'elk.algorithm': 'layered',
  'elk.direction': 'RIGHT',
  'elk.layered.spacing.edgeNodeBetweenLayers': '150',
  'elk.spacing.nodeNode': '200',
  'elk.layered.nodePlacement.strategy': 'SIMPLE',
};
const elk = new ELK();

export const parseNode = node => {
  const { id, label, name, inputs, outputs, position, type = 'pathwayNode', data } = node;

  return {
    id,
    data: {
      id,
      label,
      name,
      inputs,
      outputs,
      ...data,
    },
    position,
    type,
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  };
};

export const serializeHandle = (handle, type) => {
  return handle.split(`_${type}_`);
};

export const applyNodeInfo = (nodes, nodeInfo) => {
  return nodes.map(node => {
    let label = nodeInfo[node.id]?.name || node.label;
    let type = NODE_TYPES.pathway;
    const info = nodeInfo?.[node.id] ?? {};
    const { system_id: systemId, name } = info;
    const data = {};

    if (systemId) {
      label = name || label;
      type = NODE_TYPES.system;
      data.system = {
        id: systemId,
        system_type: SYSTEM_NAMES_TO_TYPES[node.name],
        name: label,
      };
    }

    return { ...node, label, type, data };
  });
};

/**
 * Serializes an edge into a format suitable for the server.
 * @param {Object} edge - The edge object to be serialized.
 * @returns {Object} - The serialized edge object in server format.
 */
export const serializeEdge = edge => {
  const { sourceHandle, targetHandle } = edge;
  const [output_node, output_name] = serializeHandle(sourceHandle, 'output');
  const [input_node, input_name] = serializeHandle(targetHandle, 'input');

  return {
    input_name,
    input_node,
    output_name,
    output_node,
  };
};

/**
 * Converts pathway nodes returned from the server into a format compatible with React Flow.
 * @param {Object[]} nodes - An array of pathway nodes to be parsed into React Flow format.
 * @returns {Object[]} - An array of nodes parsed into React Flow format.
 */
export const parseNodes = nodes => {
  return nodes.map(node => parseNode(node));
};

/**
 * Converts an connection received from the server into a format compatible with React Flow.
 * @param {Object} edge - The edge/connection object received from the server.
 * @returns {Object} - The parsed edge object in React Flow format.
 */
const parseEdge = edge => {
  const { sourceHandle, targetHandle, source, target, label, name, anchorable, unit } = edge;

  return {
    id: `${sourceHandle}-${targetHandle}`,
    label,
    source,
    target,
    type: 'pathwayEdge',
    markerEnd: {
      type: MarkerType.Arrow,
      width: 20,
      height: 20,
      color: '#000000',
    },
    style: {
      strokeWidth: 2,
      stroke: '#000000',
    },
    sourceHandle,
    targetHandle,
    selected: true,
    name,
    data: {
      name,
      anchorable,
      unit,
    },
  };
};

/**
 * Converts an array of node objects into a map where each key represents a node ID,
 * and the corresponding value is a node object augmented with inputs and outputs indexed by name.
 * @param {Object[]} nodes - An array of node objects to be transformed into a map.
 * @returns {Object<string, Object>} - A map where keys are node IDs and values are node objects
 * with inputs and outputs indexed by name.
 */
export const getNodesById = nodes => {
  return keyBy(nodes, 'id', node => ({
    ...node,
    inputsByName: keyBy(node.inputs, 'name'),
    outputsByName: keyBy(node.outputs, 'name'),
  }));
};

/**
 * Converts pathway connections returned from the server into React Flow edges.
 * @param {Object[]} connections - An array of pathway connections to be parsed into edges.
 * @param {Object<string, Object>} nodesById - A map where keys are node IDs and values are node objects
 * @returns {Object[]} - An array of React Flow edges parsed from the pathway connections.
 */
export const parseEdges = (connections, nodesById) => {
  return connections.map(edge => {
    const { input_node: target, input_name: inputName, output_node: source, output_name: outputName } = edge;
    const output = nodesById[source].outputsByName[outputName];
    const sourceHandle = `${source}_output_${outputName}`;
    const targetHandle = `${target}_input_${inputName}`;
    const outputType = output.type;
    const label = outputType.label;
    const unit = outputType.unit;
    const name = outputName;
    const anchorable = output.anchorable;

    return parseEdge({ sourceHandle, targetHandle, source, target, label, name, anchorable, unit });
  });
};

// position each node
export const getLayoutedNodes = async (nodes, cachedNodes, edges) => {
  const graph = {
    id: 'root',
    layoutOptions,

    children: nodes.map(n => {
      const targetPorts = n.data.inputs.map(o => ({
        id: `${n.id}_input_${o.name}`,
      }));

      const sourcePorts = n.data.outputs.map(o => ({
        id: `${n.id}_output_${o.name}`,
      }));

      return {
        id: n.id,
        width: n.width ?? 400,
        height: n.height ?? 400,
        properties: {
          'org.eclipse.elk.portConstraints': 'FIXED_SIDE',
        },
        ports: [{ id: n.id }, ...targetPorts, ...sourcePorts],
      };
    }),
    edges: edges.map(e => ({
      id: e.id,
      sources: [e.sourceHandle || e.source],
      targets: [e.targetHandle || e.target],
    })),
  };

  const layoutedGraph = await elk.layout(graph);
  const layoutedNodes = nodes.map(node => {
    const layoutedNode = layoutedGraph.children?.find(lgNode => lgNode.id === node.id);

    return {
      ...node,
      position: {
        x: cachedNodes?.[node.id]?.x ?? layoutedNode?.x ?? 0,
        y: cachedNodes?.[node.id]?.y ?? layoutedNode?.y ?? 0,
      },
    };
  });

  return layoutedNodes;
};

export const getNodeOptions = nodes => nodes.map(({ id, label }) => ({ label, value: id }));

export const getIOOptions = node => {
  const outputResult =
    node?.outputs
      .filter(output => output.allocatable && output.levelizable !== false)
      .map(output => ({
        value: JSON.stringify({ io_name: output.name, io_type: 'output' }),
        label: output.label,
      })) || [];

  const inputResult =
    node?.inputs
      .filter(input => input.levelizable === true)
      .map(input => ({
        value: JSON.stringify({ io_name: input.name, io_type: 'input' }),
        label: input.label,
      })) || [];

  return [...outputResult, ...inputResult];
};

export const getOutputOptions = node => {
  return node?.outputs
    .filter(output => output.allocatable && output.levelizeable !== false)
    .map(output => ({
      value: output.name,
      label: output.label,
    }));
};

export const getEmissionTypes = nodes => {
  const emissionTypes = nodes.flatMap(getEmissionTypesPerNode);
  const emissionTypesByLabel = keyBy(emissionTypes, 'label');
  const result = Object.values(emissionTypesByLabel);

  result.unshift(CO2_EQUIVALENT_EMISSION_TYPE);

  return result;
};

const getEmissionTypesPerNode = node => {
  const emissionTypes = node?.emission_types?.filter(({ name }) => name !== CO2_EQUIVALENT) ?? [];
  const result = [];

  emissionTypes.forEach(({ name, label, unit }) => {
    result.push({ value: JSON.stringify({ co2_equivalent: false, emission_type: name, unit, label }), label });
    result.push({
      value: JSON.stringify({ co2_equivalent: true, emission_type: name, unit, label }),
      label: `${label} (in CO2e)`,
    });
  });

  return result;
};

export const getUnitsByIO = node => {
  const outputResult =
    (node?.outputs ?? [])
      .filter(output => output.allocatable && output.levelizable !== false)
      .map(output => ({
        io: JSON.stringify({ io_name: output.name, io_type: 'output' }),
        units: [output.type.unit, ...output.type.convertible_units],
      })) || [];

  const inputResult =
    (node?.inputs ?? [])
      .filter(input => input.levelizable === true)
      .map(input => ({
        io: JSON.stringify({ io_name: input.name, io_type: 'input' }),
        units: [input.type.unit, ...input.type.convertible_units],
      })) || [];

  return keyBy([...outputResult, ...inputResult], 'io');
};

export const getUnitOptions = units => {
  const uniqueUnits = new Set();

  return units
    ?.map(unit => ({
      label: unitToString(unit),
      value: JSON.stringify(unit),
    }))
    .filter(option => {
      if (uniqueUnits.has(option.value)) {
        return false;
      } else {
        uniqueUnits.add(option.value);
        return true;
      }
    });
};

export const isPathwaySucceeded = pathway =>
  pathway?.analysis_status?.tea === ANALYSIS_STATUS.succeeded &&
  pathway?.analysis_status?.lca === ANALYSIS_STATUS.succeeded &&
  !pathway?.analysis_stale;

export const getSucceededPathways = pathways => pathways.filter(pathway => isPathwaySucceeded(pathway));

export const getDefaultLevelization = (node, selectedLevelizeBy = null) => {
  const unitsByIO = getUnitsByIO(node);
  const levelizeByOptions = getIOOptions(node);

  const option = levelizeByOptions.find(o => o.value === selectedLevelizeBy) ?? levelizeByOptions?.[0];
  const levelizeBy = option?.value;
  const levelizeByLabel = option?.label;

  const levelizeUnitOptions = getUnitOptions(unitsByIO?.[levelizeBy]?.units);
  const levelizeUnit = levelizeUnitOptions?.[0]?.value;

  const { io_name, io_type } = JSON.parse(levelizeBy || '{}');
  const unit = JSON.parse(levelizeUnit || '{}');

  return {
    levelizeBy,
    levelizeUnit,
    levelizeByLabel,
    levelizeByOptions,
    levelizeUnitOptions,
    levelization: {
      io_name,
      io_type,
      unit,
    },
  };
};
