import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import InputBase from '@/components/core/form/InputBase';
import { isDefined } from '@/utils/miscUtils';

export const NumericFormatInput = ({
  id,
  name,
  disabled,
  placeholder,
  hasError,
  isDefault,
  control,
  onBlur,
  onChange,
  onNumericChange,
  className,
  readOnly,
  rules = {},
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value: fieldValue, onChange: onFieldChange } }) => (
        <NumericFormat
          id={id}
          value={fieldValue}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          customInput={InputBase}
          onBlur={e => {
            onBlur?.(e);
          }}
          onChange={e => {
            onChange?.(e);
          }}
          onValueChange={values => {
            const numericValue = values.floatValue;
            if (isDefined(onNumericChange)) {
              onNumericChange(numericValue);
            } else {
              onFieldChange(numericValue);
            }
          }}
          allowNegative
          thousandSeparator=","
          decimalSeparator="."
          hasError={hasError}
          isDefault={isDefault}
          className={className}
        />
      )}
    />
  );
};

NumericFormatInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  isDefault: PropTypes.bool,
  control: PropTypes.object.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onNumericChange: PropTypes.func,
  className: PropTypes.string,
  rules: PropTypes.object,
  readOnly: PropTypes.bool,
};
