import { paramActions } from '@/stores/paramStore';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useParamsContext } from '../providers/useParamsContext';

const usePatchParams = () => {
  const urlParams = useParams();
  const { setError } = useFormContext();
  const { patch } = useParamsContext();

  const prepareParams = (paramName, key, value) => {
    const [parent, ...rest] = paramName.split('.');
    const finalValue = key ? { [key]: { value } } : value;

    return {
      [parent]:
        rest.length > 0
          ? {
              params: rest.reduceRight((acc, part) => ({ [part]: acc }), finalValue),
            }
          : finalValue,
    };
  };

  const innerPatch = async (fieldName, params) => {
    try {
      paramActions.setSavePending(true);
      await patch({ params }, urlParams);
      paramActions.setSavePending(false);
    } catch (error) {
      const message = error?.response?.data?.params?.[fieldName] ?? 'An error occurred';
      setError(fieldName, { type: 'custom', message });
      paramActions.setSavePending(null);
      throw error;
    }
  };

  const patchParam = async (paramName, key, value, fieldName = paramName) => {
    const params = prepareParams(paramName, key, value);
    return innerPatch(fieldName, params);
  };

  const patchPrimary = async (paramName, value, fieldName = paramName) => {
    return patchParam(paramName, 'primary', value, fieldName);
  };

  const patchUnit = (paramName, value, fieldName = paramName) => {
    return patchParam(paramName, 'unit', value, fieldName);
  };

  const addBlock = (paramName, index) => {
    return patchParam(`${paramName}.${index}`, null, {});
  };

  const removeBlock = (paramName, index) => {
    return patchParam(`${paramName}.${index}`, null, null);
  };

  const patchGroup = (paramName, params) => {
    return innerPatch(paramName, params);
  };

  const uploadFile = async (paramName, key, value, file, fieldName = paramName) => {
    const formData = new FormData();
    formData.append('file', file);

    const params = prepareParams(paramName, key, value);
    formData.append('params', JSON.stringify(params));

    try {
      paramActions.setSavePending(true);
      const response = await patch(formData, urlParams, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      paramActions.setSavePending(false);

      return response;
    } catch (error) {
      const message = error?.response?.data?.params?.[fieldName] ?? 'An error occurred';
      setError(fieldName, { type: 'custom', message });
      paramActions.setSavePending(null);
      throw error;
    }
  };

  return { patchGroup, patchPrimary, patchUnit, patchParam, addBlock, removeBlock, uploadFile };
};

export default usePatchParams;
