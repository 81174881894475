import PropTypes from 'prop-types';
import FormRow from '@/components/core/form/FormRow';
import { useFormContext } from 'react-hook-form';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import usePatchParams from '../usePatchParams';
import LocationBase from '@/components/core/form/LocationBase';

const LocationField = ({ param, paramSchema, name }) => {
  const { label, tooltip, source, allow_null } = paramSchema;
  const {
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);
  const errorMessage = error?.message?.primary?.value;
  const { patchPrimary } = usePatchParams();
  const { primary: { value, is_default: isDefault } = {} } = param ?? {};

  const handlePlaceChanged = async ({ lat, lon, address }) => {
    await patchPrimary(name, { lat, lon, address });
  };

  const handleBlur = async ({ lat, lon, address }) => {
    await patchPrimary(name, { lat, lon, address });
  };

  return (
    <FormRow label={label} tooltip={tooltip} source={source} name={name}>
      <div className="flex flex-col flex-1">
        <LocationBase
          name={name}
          value={value}
          isDefault={isDefault}
          onPlaceChanged={handlePlaceChanged}
          onBlur={handleBlur}
          shouldValidate={!allow_null}
        />
        <ErrorMessage text={errorMessage} />
      </div>
    </FormRow>
  );
};

LocationField.propTypes = {
  param: PropTypes.object,
  paramSchema: PropTypes.object,
  index: PropTypes.number,
  name: PropTypes.string,
  defaultValue: PropTypes.object,
  watchedFields: PropTypes.object,
  children: PropTypes.node,
};

export default LocationField;
