import LocationEditForm from '@/components/nodes/LocationEditForm';
import { FormProvider, useForm } from 'react-hook-form';

const LocationEditRoute = () => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <LocationEditForm />
    </FormProvider>
  );
};

export default LocationEditRoute;
