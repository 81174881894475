import PropTypes from 'prop-types';
import FormRow from '@/components/core/form/FormRow';
import { useFormContext } from 'react-hook-form';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import ConversionField from '../ConversionField';
import { isInteger, isNumber } from '@/utils/miscUtils';
import usePatchParams from '../usePatchParams';
import { useParamsContext } from '@/components/providers/useParamsContext';

const setValueAs = v => {
  if (isInteger(v)) {
    return parseInt(v);
  }

  if (isNumber(v)) {
    return parseFloat(v);
  }

  return v;
};

const SelectField = ({ paramSchema, name, param }) => {
  const { label, tooltip, source, convertible_units: convertibleUnits, allow_null: allowNull, editable } = paramSchema;
  const { primary: { value, is_default: isDefault } = {}, unit, options = [] } = param ?? {};
  const { readOnly } = useParamsContext();
  const disabled = !editable || readOnly;

  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);
  const errorMessage = error?.message?.primary?.value;
  const { patchPrimary } = usePatchParams();
  const registration = register(name, {
    setValueAs,
    onChange: async e => {
      const convertedValue = setValueAs(e.target.value);
      await patchPrimary(name, convertedValue);
    },
    disabled,
  });

  return (
    <FormRow label={label} name={name} tooltip={tooltip} source={source}>
      <div className="flex flex-col flex-1">
        <div className="join flex">
          <select
            id={name}
            name={name}
            {...registration}
            value={value ?? ''}
            className={`select select-bordered select-sm w-full ${!isDefault ? 'font-bold placeholder-shown:font-normal' : ''} ${errorMessage ? 'input-error' : ''}`}
          >
            {allowNull && <option value=""></option>}
            {options.map(option => (
              <option value={option.value} key={`${name}-${option.value}`}>
                {option.label || option.value}
              </option>
            ))}
          </select>
          {convertibleUnits?.length > 0 && (
            <ConversionField unit={unit} name={`conversion.${name}`} paramName={name} units={convertibleUnits} />
          )}
        </div>
        <ErrorMessage text={errorMessage} />
      </div>
    </FormRow>
  );
};

SelectField.propTypes = {
  paramSchema: PropTypes.object,
  param: PropTypes.object,
  name: PropTypes.string,
  prefix: PropTypes.string,
  watchedFields: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SelectField;
