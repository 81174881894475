export const ANALYSIS_STATUS = {
  pending: 'pending',
  running: 'running',
  succeeded: 'succeeded',
  failed: 'failed',
};

export const BALANCE_STATUS = {
  pending: 'pending',
  running: 'running',
  succeeded: 'succeeded',
  failed: 'failed',
};

export const SENSITIVITY_STATUS = {
  pending: 'pending',
  running: 'running',
  succeeded: 'succeeded',
  failed: 'failed',
};

export const SYSTEM_STATUS = {
  pending: 'pending',
  running: 'running',
  succeeded: 'succeeded',
  failed: 'failed',
};

export const NODE_TYPES = {
  system: 'systemNode',
  pathway: 'pathwayNode',
};

export const USER_ROLES = ['member', 'admin'];

export const VIEW_TYPES = {
  grid: 'grid',
  tabs: 'tabs',
};

export const SYSTEM_NAMES_TO_TYPES = {
  HydrogenSystem: 'hydrogen_production',
  PowerSystem: 'power_greenfield',
  AssetElectricityModel: 'data_center_greenfield',
};

export const SYSTEM_TYPE_TO_LABEL = {
  hydrogen_production: 'Hydrogen Production System',
  power_greenfield: 'Power System',
  data_center_greenfield: 'Asset Electricity Model',
};

export const SYSTEM_TYPES_OPTIONS = [
  {
    label: 'Hydrogen Production',
    value: 'hydrogen_production',
  },
  {
    label: 'Power System',
    value: 'power_greenfield',
  },
  {
    label: 'Asset Electricity Model',
    value: 'data_center_greenfield',
  },
];

export const FLOW_RATE_TYPES = [
  {
    label: 'Year',
    value: 1,
  },
  {
    label: 'Day',
    value: 365,
  },
  {
    label: 'Hour',
    value: 8760,
  },
];

export const TIME_UNIT_OPTIONS = [
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Day',
    value: 'day',
  },
  {
    label: 'Hour',
    value: 'hour',
  },
];

export const FLOW_RATE_MAP = {
  year: 1,
  day: 365,
  hour: 8760,
};

// how often should the status of the system or analysis be checked
export const CHECK_FIRST_DELAY = 1000; // 1 s
export const CHECK_SECOND_DELAY = 3000; // 3 s
// how many times the first check should run before the second delay kicks in
export const CHANGE_DELAY_AFTER = 5;

export const ELECTRICITY_TYPE = 'electricity';

export const CO2_EQUIVALENT = 'co2_equivalent';

export const LINE_CHART = 'line';
export const BAR_CHART = 'bar';

export const CO2_EQUIVALENT_EMISSION_TYPE = {
  label: 'CO₂ Equivalent',
  value: JSON.stringify({
    co2_equivalent: true,
    name: CO2_EQUIVALENT,
    label: 'CO₂ Equivalent',
    emission_type: CO2_EQUIVALENT,
    unit: {
      numerator: [{ name: 'kg', species: null }],
      denominator: [],
      measure: 'mass',
      label: 'kg',
    },
  }),
};

export const CHART_COLORS = [
  '#639EFF',
  '#FF8F12',
  '#ABC4FB',
  '#FC684C',
  '#E4DFFF',
  '#8278F5',
  '#fdbf6f',
  '#FF8F12',
  '#EAE8A0',
  '#DBD961',
  '#B2F0DC',
  '#66E2B9',
  '#0066FF',
  '#C8C9BF',
];

export const HOURS_PER_WEEK = 168;
export const HOURS_PER_MONTH = 730;
export const HOURS_PER_YEAR = 8760;

export const flowRateOptions = [
  { label: 'Week', value: HOURS_PER_WEEK },
  { label: 'Month', value: HOURS_PER_MONTH },
  { label: 'Year', value: HOURS_PER_YEAR },
];

export const flowRateMap = {
  [HOURS_PER_WEEK]: 'Week',
  [HOURS_PER_MONTH]: 'Month',
  [HOURS_PER_YEAR]: 'Year',
};

// starting hours for each month
export const startingHours = [0, 744, 1416, 2160, 2900, 3623, 4343, 5090, 5840, 6560, 7300, 8020];

export const PARAM_TYPES_WITH_DEPENDENCIES = ['hourly_timeseries'];

export const ANALYSIS_TYPES = {
  sensitivity: 'Sensitivity',
  mc: 'Monte Carlo',
  lca: 'LCA',
  tea: 'TEA',
};
