import PropTypes from 'prop-types';

import { Chart } from 'react-chartjs-2';
import { useRef } from 'react';
import ExportDropdown from './ExportDropdown';
import { numberFormat } from '@/utils/unitUtils';

const distributionOptions = {
  emissions: {
    backgroundColor: '#D0E2FF',
    borderColor: '#A2C5FF',
  },
  levelized_cost: {
    backgroundColor: '#FFBC71',
    borderColor: '#E8750F',
  },
};

const buildOptions = (labels, xAxisLabel, scalar) => ({
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      title: { display: true, text: xAxisLabel },
      ticks: {
        callback: (_value, index, _ticks) => {
          return index % 4 == 0 ? numberFormat(labels[index] * scalar) : null;
        },
      },
    },
    y: {
      beginAtZero: true,
      title: { display: true, text: 'Probability' },
    },
  },
});

const buildData = (data, labels, distribution, scalar) => {
  const { backgroundColor, borderColor } = distributionOptions[distribution];
  const adjustedLabels = labels.map(label => `${numberFormat(label * scalar)}`);

  return {
    labels: adjustedLabels,
    datasets: [
      {
        type: 'bar',
        backgroundColor,
        data,
        borderColor,
        borderWidth: 1,
        barPercentage: 1,
        categoryPercentage: 1,
      },
    ],
  };
};

const MCChart = ({ data, labels, distribution, xAxisLabel, scalar }) => {
  const chartScalar = distribution === 'levelized_cost' ? scalar : 1;
  const options = buildOptions(labels, xAxisLabel, chartScalar);
  const barData = buildData(data, labels, distribution, chartScalar);
  const ref = useRef();

  return (
    <div className="min-h-88 relative w-full pt-6">
      <ExportDropdown forwardedRef={ref} data={barData} />
      <Chart ref={ref} data={barData} options={options} />
    </div>
  );
};

MCChart.propTypes = {
  data: PropTypes.array,
  labels: PropTypes.arrayOf(PropTypes.number),
  distribution: PropTypes.string,
  xAxisLabel: PropTypes.string,
  scalar: PropTypes.number,
};

export default MCChart;
