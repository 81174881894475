import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import FileInfo from '../FileInfo';
import FileTemplate from '../FileTemplate';

const FileBase = ({
  name,
  editable = true,
  onUpload,
  label,
  className = '',
  acceptedFormats = 'csv',
  onDelete,
  isError = false,
  isUploading = false,
  fileInfo = null,
  templateUrl,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const dropZoneRef = useRef(null);

  const handleFile = async file => {
    if (!file || !onUpload) return;
    await onUpload(file);
  };

  const handleFileChange = async event => {
    const file = event.target.files[0];
    await handleFile(file);
  };

  const handleDrop = async event => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    await handleFile(file);
  };

  const handleDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!editable) return;
    setIsDragging(true);
  };

  const handleDragLeave = event => {
    event.preventDefault();
    event.stopPropagation();

    const relatedTarget = event.relatedTarget;
    if (!dropZoneRef.current?.contains(relatedTarget)) {
      setIsDragging(false);
    }
  };

  if (fileInfo) {
    return (
      <div className="flex flex-col flex-1">
        <FileInfo fileInfo={fileInfo} onDelete={onDelete} editable={editable} />
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1">
      <div className="flex gap-4">
        <div className={`flex-1 ${templateUrl ? 'w-2/3' : 'w-full'}`}>
          <div
            ref={dropZoneRef}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            className={`
              border-2 border-dashed rounded-lg p-2 ${className}
              flex flex-col items-center justify-center gap-2
              transition-colors duration-200
              ${isError ? 'border-red-500' : isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}
              ${!editable ? 'opacity-50' : ''}
            `}
          >
            <div className="text-center relative">
              {isUploading ? (
                <div className="p-1">
                  <span className="loading loading-spinner h-6" />
                </div>
              ) : (
                <>
                  <div className="flex items-center justify-center gap-1">
                    <label
                      htmlFor={name}
                      className={`
                       text-purple-500 hover:text-purple-400
                      ${!editable ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
                    `}
                    >
                      Import
                      <input
                        type="file"
                        id={name}
                        name={name}
                        disabled={!editable}
                        onChange={handleFileChange}
                        className="sr-only"
                        accept={`.${acceptedFormats}`}
                      />
                    </label>
                    <p className="text-gray-500">{label}</p>
                  </div>
                  <p className="text-gray-400 mt-2 text-xs">Supported format: {acceptedFormats}</p>
                </>
              )}
            </div>
          </div>
        </div>

        {templateUrl && !isUploading && (
          <div className="w-1/3">
            <FileTemplate url={templateUrl} />
          </div>
        )}
      </div>
    </div>
  );
};

FileBase.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  onUpload: PropTypes.func,
  onDelete: PropTypes.func,
  isError: PropTypes.bool,
  isUploading: PropTypes.bool,
  acceptedFormats: PropTypes.string,
  className: PropTypes.string,
  fileInfo: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    url: PropTypes.string,
    identifier: PropTypes.string,
  }),
  templateUrl: PropTypes.string,
};

export default FileBase;
