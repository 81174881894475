import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const InputBase = forwardRef(
  (
    {
      id,
      name,
      type = 'text',
      className = '',
      placeholder = '',
      isDefault = true,
      hasError = false,
      readOnly = false,
      ...props
    },
    ref,
  ) => {
    return (
      <input
        type={type}
        ref={ref}
        id={id}
        readOnly={readOnly}
        name={name}
        className={`
        [appearance:textfield]
        [&::-webkit-outer-spin-button]:appearance-none
        [&::-webkit-inner-spin-button]:appearance-none
        input input-bordered
        input-sm
        w-full
        flex-1
        join-item
        ${className}
        ${!isDefault ? 'font-bold placeholder-shown:font-normal' : ''}
        ${hasError ? 'input-error' : ''}
      `}
        placeholder={placeholder}
        {...props}
      />
    );
  },
);

InputBase.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  isDefault: PropTypes.bool,
  readOnly: PropTypes.bool,
  hasError: PropTypes.bool,
};

InputBase.displayName = 'InputBase';

export default InputBase;
