import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useInteractions,
  FloatingPortal,
} from '@floating-ui/react';
import { useParams } from 'react-router-dom';
import { getPathwayParamInfo, getPathwayParams } from '@/api/pathways';
import PathwaySettingsForm from './PathwaySettingsForm';
import FlowRateSelector from './FlowRateSelector';
import { useIsSavePending } from '@/stores/paramStore';

const PathwaySettings = () => {
  const { pathwayId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [paramSchemas, setParamSchemas] = useState(null);
  const [params, setParams] = useState(null);
  const isPending = useIsSavePending();

  const handleOpenChange = open => {
    // Only allow closing via the cog icon
    if (!open && event?.target?.closest('.pac-container')) {
      return;
    }
    setIsOpen(open);
  };

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: handleOpenChange,
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackPlacements: ['bottom-end'],
        fallbackStrategy: 'bestFit',
      }),
      shift({ padding: 5 }),
    ],
  });

  useEffect(() => {
    if (!isOpen) return;

    const fetchData = async () => {
      const [
        {
          data: { params: paramSchemas },
        },
        {
          data: { params },
        },
      ] = await Promise.all([getPathwayParamInfo(), getPathwayParams(pathwayId)]);

      setParamSchemas(paramSchemas);
      setParams(params);
    };

    fetchData();
  }, [isOpen, pathwayId]);

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  return (
    <>
      <Cog6ToothIcon
        ref={refs.setReference}
        {...getReferenceProps()}
        role="button"
        className="h-6 w-6 text-white-500 hover:text-gray-200 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      />
      <FloatingPortal>
        {isOpen && (
          <div
            className="menu dropdown-content z-1000 w-80 rounded border border-gray-75 bg-base-100 p-4 shadow-md"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            onClick={e => e.stopPropagation()}
          >
            <div>
              <div className="flex justify-between pb-2 border-b border-gray-200">
                <p className="text-sm font-bold">Canvas Settings</p>
                <div>
                  {isPending && <span className="text-xs mr-2 text-gray-500">Saving...</span>}
                  {isPending === false && <span className="text-xs mr-2 text-gray-500">Saved</span>}
                </div>
              </div>

              <div className="group node h-full ">
                {paramSchemas?.length > 0 && <PathwaySettingsForm paramSchemas={paramSchemas} params={params} />}
                <FlowRateSelector />
              </div>
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

export default PathwaySettings;
