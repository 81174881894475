import PropTypes from 'prop-types';
import FormRow from '@/components/core/form/FormRow';
import { useFormContext } from 'react-hook-form';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import ConversionField from '../ConversionField';
import MCFieldsWrapper from '@/components/nodes/MCFieldsWrapper';
import MCFields from '@/components/nodes/MCFields';
import usePatchParams from '../usePatchParams';
import { useEffect, useRef } from 'react';
import { isDefined } from '@/utils/miscUtils';
import InputBase from '@/components/core/form/InputBase';
import { NumericFormatInput } from '@/components/core/form/NumericFormatInput';
import { useParamsContext } from '@/components/providers/useParamsContext';
import AutoEligibleField from './AutoEligibleField';
import { DefaultSourceDisplay } from '../common/DefaultSourceDisplay';
const InputField = ({ param, paramSchema, name }) => {
  const {
    label,
    type,
    tooltip,
    source,
    mc_eligible: isMcEligible,
    convertible_units: convertibleUnits,
    editable,
    auto_eligible: isAutoEligible,
  } = paramSchema;
  const {
    primary: { value, is_default: isDefault, default: defaultValue, default_source: defaultSource } = {},
    mc_min,
    mc_max,
    unit,
  } = param ?? {};
  const {
    control,
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const { readOnly } = useParamsContext();
  const isAuto = value?.auto === true;
  const disabled = !editable || readOnly || isAuto;

  const error = get(errors, name);
  const errorMessage = error?.message?.primary?.value;
  const { patchPrimary } = usePatchParams();
  const registration = register(name, { valueAsNumber: type === 'numeric', disabled });
  const numericValueRef = useRef(null);

  const events = {
    onChange: e => {
      e.preventDefault();
    },
    onBlur: async e => {
      const prevValue = getValues(name);
      const value = type === 'numeric' ? (numericValueRef.current ?? parseFloat(e.target.value)) : e.target.value;

      if (prevValue !== value) {
        await patchPrimary(name, value);
      } else {
        setValue(name, value, { shouldValidate: true, shouldDirty: false });
      }
    },
  };

  useEffect(() => {
    if (isDefined(value)) {
      if (isAuto) {
        setValue(name, 'Auto', { shouldValidate: false, shouldDirty: false });
      } else {
        setValue(name, value, { shouldValidate: true, shouldDirty: false });
      }
    }
  }, [value, name, setValue, isAuto]);

  return (
    <>
      <FormRow
        label={label}
        tooltip={tooltip}
        source={source}
        name={name}
        auto={isAutoEligible && <AutoEligibleField name={name} value={value?.auto} readOnly={readOnly} />}
      >
        <div className="flex flex-col flex-1">
          <div className="join flex">
            {type === 'numeric' && !name.endsWith('_year') && !isAuto ? (
              <NumericFormatInput
                id={name}
                name={name}
                disabled={disabled}
                readOnly={readOnly}
                control={control}
                {...events}
                placeholder={defaultValue?.toString()}
                hasError={!!errorMessage}
                isDefault={isDefault}
                onNumericChange={value => {
                  numericValueRef.current = value;
                }}
              />
            ) : (
              <InputBase
                id={name}
                {...registration}
                type="text"
                name={name}
                disabled={disabled}
                readOnly={readOnly}
                defaultValue={value}
                {...events}
                placeholder={defaultValue?.toString()}
                hasError={!!errorMessage}
                isDefault={isDefault}
              />
            )}
            {convertibleUnits?.length > 0 && (
              <ConversionField
                unit={unit}
                name={`conversion.${name}`}
                paramName={name}
                units={convertibleUnits}
                disabled={disabled}
                readOnly={readOnly}
              />
            )}
          </div>
          <ErrorMessage text={errorMessage} />
        </div>
      </FormRow>
      <div className="flex flex-row gap-2 flex-1">
        <div className="flex-1">
          {isMcEligible && !isAuto && (
            <MCFieldsWrapper mcMin={mc_min} mcMax={mc_max}>
              <MCFields name={name} mcMin={mc_min} mcMax={mc_max} />
            </MCFieldsWrapper>
          )}
        </div>
        <DefaultSourceDisplay source={defaultSource} />
      </div>
    </>
  );
};

InputField.propTypes = {
  param: PropTypes.object,
  paramSchema: PropTypes.object,
  name: PropTypes.string,
  children: PropTypes.node,
};

export default InputField;
