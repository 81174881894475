import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  analyzeCase,
  balanceCase,
  createCostOverride,
  deleteCostOverride,
  getCase,
  patchAnchor,
  patchCostOverride,
} from '@/api/cases';
import { getNodesById, serializeHandle } from '@/utils/pathwayUtils';
import { keyBy, runInterval } from '@/utils/miscUtils';
import { numberFormat, unitToString } from '@/utils/unitUtils';
import Decimal from 'decimal.js';
import { resourcesActions } from './resourcesStore';
import usePathwayStore from './pathwayStore';
import { analysisStatusActions } from './analysisStatusStore';
import { BALANCE_STATUS, CHANGE_DELAY_AFTER, CHECK_FIRST_DELAY, CHECK_SECOND_DELAY } from '@/consts';
import { toast } from 'react-toastify';

const initialValues = {
  anchor: null,
  tempAnchor: null,
  params: null,
  mc_params: null,
  balancedNodes: null,
  balance_results: null,
  balanced: false,
  analysis_id: null,
  flowRate: 1,
  cost_overrides: [],
  node_info: {},
};

const inputOutputMap = {
  input: 'inputs',
  output: 'outputs',
};

const useCaseStore = create()(immer(() => initialValues));
const { setState: set, getState: get } = useCaseStore;

// actions
export const caseActions = {
  init: caseData => {
    analysisStatusActions.init(caseData);
    caseActions.initBalancedData(caseData);
  },

  initBalancedData: caseData => {
    const { balance_status: balanceStatus, balance_error: balanceError } = caseData;
    const balancedNodes = keyBy(caseData?.balance_results ?? [], 'id');

    set({ ...caseData, balancedNodes, flowRate: 1, balanceStatus, balanceError }, true);

    if (balanceStatus === BALANCE_STATUS.succeeded) {
      set({ balanced: true });
    } else if (balanceStatus === BALANCE_STATUS.running) {
      caseActions.checkBalanceStatusPeriodically();
    }
  },

  checkBalanceStatus: async () => {
    const { id } = get();

    try {
      const { data } = await getCase(id);
      const { balance_status: balanceStatus, balance_error: balanceError } = data;

      if (balanceStatus === BALANCE_STATUS.succeeded || balanceStatus === BALANCE_STATUS.failed) {
        caseActions.initBalancedData(data);

        if (balanceError) {
          toast.error(balanceError);
        }

        return true;
      } else {
        set({ balanceStatus, balanceError });
      }
    } catch (err) {
      set({ balanceStatus: BALANCE_STATUS.failed });
      return true;
    }

    return false;
  },

  checkBalanceStatusPeriodically: () => {
    runInterval(caseActions.checkBalanceStatus, CHECK_FIRST_DELAY, CHECK_SECOND_DELAY, CHANGE_DELAY_AFTER);
  },

  patchAnchor: async (handle, handleType, scalar, unit) => {
    const { id: caseId } = get();
    const [node_id, io_name] = serializeHandle(handle, handleType);
    const anchorData = {
      node_id,
      io_type: handleType,
      io_name,
      quantity: {
        scalar,
        unit,
      },
    };

    const { data } = await patchAnchor(caseId, anchorData);

    if (data.anchor) {
      const { anchor } = data;
      set({ anchor });
      caseActions.markUnbalanced();
    }
  },

  addCost: async (nodeId, handle, handleType, cost, unit) => {
    const { id: caseId } = get();
    const params = {
      node_id: nodeId,
      io_type: 'input',
      io_name: handle,
      cost,
      unit,
    };

    const { data } = await createCostOverride(caseId, params);

    set(state => {
      state.cost_overrides.push(data);
    });

    analysisStatusActions.markAsStale();
  },

  updateCost: async (costOverride, cost, unit) => {
    const { id, node_id, io_type, io_name } = costOverride;
    const { data } = await patchCostOverride(id, { cost, unit, node_id, io_type, io_name });

    set(state => {
      state.cost_overrides = state.cost_overrides.map(costOverride => {
        if (costOverride.id === costOverride.id) {
          return data;
        }

        return costOverride;
      });
    });
    analysisStatusActions.markAsStale();
  },

  deleteCost: async costId => {
    await deleteCostOverride(costId);

    set(state => {
      state.cost_overrides = state.cost_overrides.filter(cost => cost.id !== costId);
    });

    analysisStatusActions.markAsStale();
  },

  balanceCase: async () => {
    const { id: caseId } = get();
    await balanceCase(caseId);

    set({ balanceStatus: BALANCE_STATUS.pending, balanceError: null, balance_results: null, balanced: false });
    caseActions.checkBalanceStatusPeriodically();
  },
  analyzeCase: async params => {
    const { id: caseId, pathway_id: pathwayId } = get();
    const { data } = await analyzeCase(caseId, params);
    const { status, id } = data;

    analysisStatusActions.init({ analysis_id: id, analysis_status: status });
    resourcesActions.updatePathway({ id: pathwayId, analysis_status: status });
  },
  setTempAnchor: tempAnchor => set({ tempAnchor }),
  clearTempAnchor: () => set({ tempAnchor: null }),
  markUnbalanced: () => {
    set({ balanced: false });
    if (!analysisStatusActions.isInProgress()) {
      analysisStatusActions.clear();
    }
    analysisStatusActions.markAsStale();
  },
  changeFlowRate: flowRate => set({ flowRate }),
  clear: () => {
    set(initialValues, true);
  },
};

// selectors
export const useAnchorId = () =>
  useCaseStore(store => {
    const { anchor } = store;

    if (anchor) {
      const { node_id, io_name, io_type } = anchor;
      return `${node_id}_${io_type}_${io_name}`;
    }

    return null;
  });

export const useAnchor = () => useCaseStore(store => store.anchor);
export const useCaseId = () => useCaseStore(store => store.id);
export const useTempAnchor = () => useCaseStore(store => store.tempAnchor);
export const useCostOverridesByNode = nodeId =>
  useCaseStore(store => store?.cost_overrides.find(co => co.node_id === nodeId));
export const useCostOverridesByNodeAndName = (nodeId, name) =>
  useCaseStore(store => store?.cost_overrides.find(co => co.node_id === nodeId && co.io_name === name));

export const useIsBalanced = () => useCaseStore(store => store.balanced);
export const useBalanceWarnings = () => {
  const { nodes } = usePathwayStore.getState();
  const nodesById = getNodesById(nodes);
  return useCaseStore(
    store =>
      store.balance_results?.reduce((acc, { warnings, id }) => {
        if (warnings && warnings.length > 0) {
          acc.push({ warnings, node: nodesById[id]?.data });
        }
        return acc;
      }, []) || [],
  );
};
export const useBalancedPortValue = (nodeId, nodeType, nodeName) =>
  useCaseStore(store => {
    const { flowRate } = store;
    const balancedValues = store?.balancedNodes?.[nodeId]?.[inputOutputMap[nodeType]]?.[nodeName];

    if (!balancedValues) {
      return null;
    }

    const { quantities, species } = balancedValues;
    const parsed = quantities.map(({ scalar, unit }) => {
      let value = new Decimal(scalar);

      if (species !== 'electricity') {
        value = value.dividedBy(flowRate);
      }

      const unitStr = unitToString(unit);
      const rounding = value.toNumber() >= 1 ? 2 : 5;

      return `${numberFormat(value.toNumber(), rounding)} ${unitStr}`;
    });

    return parsed;
  });

export const useFlowRate = () => useCaseStore(store => store.flowRate);

export const useIsBalancing = () =>
  useCaseStore(
    store => store.balanceStatus === BALANCE_STATUS.pending || store.balanceStatus === BALANCE_STATUS.running,
  );

export default useCaseStore;
