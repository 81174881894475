import { Link, useParams, useLoaderData, Outlet } from 'react-router-dom';
import { parseCategories } from '@/utils/paramsUtils';
import { XMarkIcon } from '@heroicons/react/24/outline';
import NodeForm from './NodeForm';
import { nodeActions, useCaseNodeParams, useNodeParams } from '@/stores/nodeStore';
import ParamsProvider from '../providers/ParamProvider';
import { caseActions } from '@/stores/caseStore';
import { getDerivedValue, patchNodeParams } from '@/api/nodes';
import { useIsPathwayLocked } from '@/stores/pathwayStore';

const updateNodeParams = async (params, urlParams) => {
  const { caseId, nodeId } = urlParams;
  const { data } = await patchNodeParams(caseId, nodeId, params);
  nodeActions.updateParams(data.params);
  caseActions.markUnbalanced();

  return data.params;
};

const fetchDerivedValues = async (name, urlParams) => {
  const { caseId, nodeId } = urlParams;
  const data = await getDerivedValue(caseId, nodeId, name);
  return data;
};

const NodeModal = () => {
  const { projectId, pathwayId } = useParams();
  const { node } = useLoaderData();
  const caseParams = useCaseNodeParams();
  const nodeParams = useNodeParams();
  const categories = parseCategories(nodeParams);
  const locked = useIsPathwayLocked();

  if (node.description) {
    categories.Description = {
      categoryName: 'Info',
      params: [],
    };
  }

  return (
    <div className="group node h-full relative">
      <ParamsProvider readOnly={locked} patch={updateNodeParams} fetchDerivedValues={fetchDerivedValues}>
        <NodeForm
          key={node.id}
          actions={
            <Link to={`/projects/${projectId}/build/pathways/${pathwayId}`}>
              <XMarkIcon className="h-5 w-5" />
            </Link>
          }
          title={node.label}
          description={node.description}
          caseParams={caseParams}
          categories={categories}
        />
        <div className="absolute w-full top-0 z-1000 bg-white">
          <Outlet />
        </div>
      </ParamsProvider>
    </div>
  );
};

export default NodeModal;
