import { Link, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';
import LocationBase from '../core/form/LocationBase';
import { useFormContext } from 'react-hook-form';
import usePatchParams from '../parameters/usePatchParams';
import { useCaseNodeParams } from '@/stores/nodeStore';

const LocationEditForm = () => {
  const { node } = useRouteLoaderData('node');
  const caseParams = useCaseNodeParams();
  const navigate = useNavigate();
  const { patchPrimary } = usePatchParams();
  const { handleSubmit } = useFormContext();
  const paramSchema = node?.params?.find(param => param.type === 'location');
  const param = caseParams[paramSchema?.name];

  const onSubmit = async (data, event) => {
    event.preventDefault();

    const value = data?.location?.address ? data.location : null;
    await patchPrimary(paramSchema.name, value);

    navigate('..');
  };

  return (
    <div className="shadow-md p-2 m-2 rounded-sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-1">
          <div>
            <LocationBase name={paramSchema?.name} value={param?.primary?.value} autoFocus shouldValidate={false} />
          </div>
          <div className="flex gap-2 justify-end">
            <Link to={'..'} replace className="btn btn-xs px-1 btn-ghost border-zinc-400">
              <XMarkIcon className="h-5 w-5 text-zinc-400" />
            </Link>
            <button type="submit" className="btn btn-xs px-1 text-white bg-purple-500 border-purple-500">
              <CheckIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LocationEditForm;
