import LCAPlot from './lca/LCAPlot';
import TEAPlot from './tea/TEAPlot';
import CashFlowsPlot from './cashFlows/CashFlowsPlot';
import Balance from './balance/Balance';
import MCPlot from './mc/MCPlot';
import SensitivityPlot from './sensitivity/SensitivityPlot';
import LCABreakdownPlot from './lca/LCABreakdownPlot';
import { useEffect, useRef } from 'react';
import Characteristics from './characteristics/Characteristics';
import WorkbenchTable from './workbench/WorkbenchTable';
import CostBreakdown from './costBreakdown/CostBreakdown';
import TEABreakdownPlot from './tea/TEABreakdownPlot';
import ExportButton from '../core/ExportButton';
import { useIsAnalysisInProgress } from '@/stores/analysisStatusStore';
import { WorkbenchPlotsSection } from './workbench/WorkbenchPlotsContainer';

const Report = () => {
  const blockRef = useRef(null);
  const isAnalysisInProgress = useIsAnalysisInProgress();

  useEffect(() => {
    blockRef.current.focus();
  }, []);

  return (
    <div ref={blockRef} className="relative bg-canvas flex flex-col gap-4 px-4 pb-4 focus:outline-none" tabIndex={-1}>
      {!isAnalysisInProgress && <ExportButton />}

      <div className="grid gap-4 grid-cols-2">
        <LCAPlot />
        <TEAPlot />
      </div>

      <LCABreakdownPlot />

      <TEABreakdownPlot />

      <div className="grid gap-4 grid-cols-2">
        <Characteristics />
        <CostBreakdown />
      </div>

      <div className="grid gap-4 grid-cols-2">
        <Balance />
        <MCPlot />
      </div>

      <div>
        <SensitivityPlot type="lca" label="Emissions Analysis Sensitivity" />
      </div>

      <div>
        <SensitivityPlot type="tea" label="Techno-Economic Analysis Sensitivity" />
      </div>

      <div>
        <WorkbenchTable />
      </div>

      <div>
        <WorkbenchPlotsSection />
      </div>

      <div>
        <CashFlowsPlot />
      </div>
    </div>
  );
};

export default Report;
