import { useNavigation } from 'react-router-dom';
import PropTypes from 'prop-types';

const SubmitButton = ({ label, isPending = false, isDisabled = false }) => {
  const { state } = useNavigation();
  const pending = state === 'loading' || state === 'submitting' || isPending;
  const disabled = isDisabled || pending;

  return (
    <button type="submit" className={`btn btn-primary btn-sm ${disabled ? 'btn-disabled' : ''}`}>
      {pending && <span className="loading loading-spinner"></span>}
      {pending ? 'Please wait...' : label}
    </button>
  );
};

export default SubmitButton;

SubmitButton.propTypes = {
  label: PropTypes.string.isRequired,
  isPending: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
