import { useCharacteristics, useNodeById } from '@/stores/analysisStore';
import { analysisActions } from '@/stores/analysisStore';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';
import CharacteristicsForm from './CharacteristicsForm';
import { numberFormat } from '@/utils/unitUtils';

const Characteristics = () => {
  const plot = useCharacteristics();
  const { nodeId, results } = plot;
  const node = useNodeById(nodeId);
  const updatePlot = formData => analysisActions.updatePlot('characteristics', formData);

  if (!node || !results) {
    return null;
  }

  return (
    <AnalysisContainer title="Plant Characteristics and Costs">
      <AnalysisBox>
        {results?.map(({ header, rows }) => {
          return (
            rows &&
            rows.length > 0 && (
              <div className="prose max-w-full" key={header}>
                <h4>{header}</h4>
                <table className="prose text-xs min-w-full">
                  <tbody>
                    {rows.map(({ label, amount, unit }) => (
                      <tr key={`${header}-${label}`}>
                        <td className="w-4/6">{label}</td>
                        <td className="w-1/6">{amount && numberFormat(amount, 2)}</td>
                        <td className="w-1/6">{unit}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          );
        })}
        <CharacteristicsForm onSubmit={updatePlot} defaultValues={plot} />
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default Characteristics;
