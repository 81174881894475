import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import config from '@/config';
import { isDefined } from '@/utils/miscUtils';
import InputBase from '@/components/core/form/InputBase';

const libraries = ['places'];

const isValidLatLng = latLngStr => {
  const parts = latLngStr.split(',');

  if (parts.length !== 2) return false;

  const [latStr, lonStr] = parts;
  const lat = Number(latStr);
  const lon = Number(lonStr);

  return lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180;
};

const LocationBase = ({
  name,
  autoFocus = false,
  value,
  isDefault,
  onPlaceChanged,
  onBlur,
  hasError,
  shouldValidate = false,
}) => {
  const { register, setValue, watch, trigger } = useFormContext();

  const inputRef = useRef(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const address = watch(`${name}.address`);
  const lat = watch(`${name}.lat`);
  const lon = watch(`${name}.lon`);
  const options = {};

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsApiKey,
    libraries,
  });

  if (shouldValidate) {
    options.validate = () => (address && lat && lon) || 'Address is required';
  }

  const registration = register(name, options);

  useEffect(() => {
    if (isDefined(value)) {
      setValue(`${name}.address`, value.address, { shouldValidate: true });
      setValue(`${name}.lat`, value.lat);
      setValue(`${name}.lon`, value.lon);
    } else {
      setValue(name, null);
    }
  }, [value, name, setValue]);

  const handlePlaceChanged = async () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const lat = place.geometry?.location?.lat();
      const lon = place.geometry?.location?.lng();
      const address = place.formatted_address || '';

      if (onPlaceChanged) {
        onPlaceChanged({ lat, lon, address });
      }

      setValue(`${name}.lat`, lat);
      setValue(`${name}.lon`, lon);
      setValue(`${name}.address`, address, { shouldDirty: true });
      setTimeout(() => {
        trigger(name);
      }, 0);
    }
  };

  const handleClearInput = () => {
    setValue(`${name}.address`, '', { shouldDirty: true });
    setValue(`${name}.lat`, null);
    setValue(`${name}.lon`, null);

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !autocomplete.getPlace()) {
      e.preventDefault();
    }
  };

  const handleBlur = async () => {
    const address = inputRef.current?.value;

    if (isValidLatLng(address)) {
      const [lat, lon] = address.split(',').map(Number);
      setValue(`${name}.lat`, lat, { shouldDirty: true });
      setValue(`${name}.lon`, lon, { shouldDirty: true });
      setValue(`${name}.address`, address, { shouldDirty: true });

      if (onBlur) {
        onBlur({ lat, lon, address });
      }
    }

    setTimeout(() => {
      trigger(name);
    }, 0);
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <div className="relative flex">
        <Autocomplete className="w-full" onLoad={setAutocomplete} onPlaceChanged={handlePlaceChanged}>
          <InputBase
            id={name}
            {...registration}
            type="text"
            ref={inputRef}
            name={`${name}.address`}
            defaultValue={value?.address}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            hasError={!!hasError}
            isDefault={isDefault}
            autoFocus={autoFocus}
            className="pr-8"
          />
        </Autocomplete>
        {address && (
          <button
            type="button"
            onClick={handleClearInput}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        )}
      </div>
      {lat && lon && (
        <div className="text-xs text-gray-500 mt-1">
          lat: {lat}, lon: {lon}
        </div>
      )}
    </>
  );
};

LocationBase.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  hasError: PropTypes.bool,
  isDefault: PropTypes.bool,
  onPlaceChanged: PropTypes.func,
  onBlur: PropTypes.func,
  shouldValidate: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default LocationBase;
