import PropTypes from 'prop-types';
import { useConversionsByTypeName } from '@/stores/anchorStore';
import { FormProvider, useForm } from 'react-hook-form';
import Select from '../core/form/Select';
import ErrorMessage from '../parameters/fields/ErrorMessage';
import { validatorRules } from '@/utils/validators';
import { getCostsOptions } from '@/utils/unitUtils';
import { caseActions, useCostOverridesByNodeAndName, useTempAnchor } from '@/stores/caseStore';
import { isEmpty } from '@/utils/miscUtils';
import { NumericFormatInput } from '../core/form/NumericFormatInput';

const CostOverridesForm = ({ onToggleForm, onClose }) => {
  const tempanch = useTempAnchor();
  const { name, handleType, id } = tempanch;
  const costOverride = useCostOverridesByNodeAndName(id, name);

  const defaultValues = {
    cost: costOverride?.cost ?? 0,
    unit: costOverride ? JSON.stringify(costOverride?.unit) : undefined,
  };

  const onSubmit = data => {
    const { unit: unitStr, cost } = data;
    const unit = JSON.parse(unitStr);

    if (costOverride) {
      caseActions.updateCost(costOverride, cost, unit);
    } else {
      caseActions.addCost(id, name, handleType, cost, unit);
    }

    onToggleForm();
    onClose();
  };

  const methods = useForm({ defaultValues, mode: 'onChange' });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = methods;

  const conversions = useConversionsByTypeName(handleType, name);
  const options = getCostsOptions(conversions);

  const checkKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  const submit = data => {
    onSubmit(data);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} onKeyDown={checkKeyDown} className="mt-7">
        <div className="join w-full">
          <div className="flex-1">
            <NumericFormatInput
              id="cost"
              name="cost"
              placeholder="Enter cost"
              control={control}
              rules={{ required: true, valueAsNumber: true, validate: validatorRules.numeric() }}
              className="w-full"
            />
            {errors.cost?.message && <ErrorMessage text={errors.cost?.message.toString()} />}
          </div>
          <Select name="unit" options={options} className="join-item select-sm border-l-0" />
        </div>
        <div className="modal-action">
          <button type="submit" className={`btn btn-primary btn-sm ${isEmpty(dirtyFields) ? 'btn-disabled' : ''}`}>
            Save
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

CostOverridesForm.propTypes = {
  onToggleForm: PropTypes.func,
  onClose: PropTypes.func,
};

export default CostOverridesForm;
