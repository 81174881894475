import PropTypes from 'prop-types';
import { formatFileSize } from '@/utils/miscUtils';
import { DocumentIcon, TrashIcon } from '@heroicons/react/24/outline';

const FileInfo = ({ fileInfo, onDelete, editable = true }) => {
  return (
    <div className="border rounded-lg p-2 flex items-center gap-4">
      <div className="text-gray-600">
        <DocumentIcon className="w-6 h-6" />
      </div>
      <div className="flex-1">
        <div className="font-medium">{fileInfo.filename}</div>
        <div className="text-xs text-gray-500">
          .{fileInfo.type} | {formatFileSize(fileInfo.size)}
        </div>
      </div>
      <div className="flex items-center gap-2">
        {editable && (
          <button
            type="button"
            className="p-2 text-gray-600 hover:text-red-600 rounded-full hover:bg-gray-100"
            onClick={onDelete}
          >
            <TrashIcon className="w-5 h-5" />
          </button>
        )}
      </div>
    </div>
  );
};

FileInfo.propTypes = {
  fileInfo: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};

export default FileInfo;
