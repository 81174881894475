import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { registryActions, useActiveCategory } from '@/stores/registryStore';
import SearchInput from '../core/form/SearchInput';
import usePrevious from '@/hooks/usePrevious';
import { memo, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsFeatureEnabled } from '@/stores/settingsStore';

const RegistryForm = ({ defaultValues, onSubmit }) => {
  const navigate = useNavigate();
  const { projectId, pathwayId } = useParams();
  const activeCategory = useActiveCategory();
  const prevActiveCategory = usePrevious(activeCategory);
  const methods = useForm({ defaultValues, mode: 'onChange' });
  const { handleSubmit, setValue, getValues } = methods;
  const isExcelUploadEnabled = useIsFeatureEnabled('excel_upload');

  const submit = useCallback(
    data => {
      if (data.term.length && activeCategory !== 'All') {
        registryActions.setActiveCategory('All');
      } else if (data?.term?.length === 0 && activeCategory === 'All') {
        registryActions.setActiveCategory(prevActiveCategory);
      }
      onSubmit(data);
    },
    [activeCategory, onSubmit, prevActiveCategory],
  );

  useEffect(() => {
    if (activeCategory !== 'All' && prevActiveCategory) {
      const values = getValues();
      setValue('term', '');
      submit({ ...values, ...{ term: '' } });
    }
  }, [activeCategory, prevActiveCategory, setValue, getValues, submit]);

  const handleImportClick = () => {
    navigate(`/projects/${projectId}/build/pathways/${pathwayId}/import-model`);
  };

  return (
    <FormProvider {...methods}>
      <form
        className="module-search flex items-center gap-2"
        onSubmit={handleSubmit(submit)}
        onChange={handleSubmit(submit)}
      >
        {isExcelUploadEnabled && (
          <button
            type="button"
            onClick={handleImportClick}
            className="transition bg-orange-100 hover:bg-orange-200 rounded-full text-xs items-center py-1 px-2"
          >
            Import Model
          </button>
        )}
        <SearchInput name="term" />
      </form>
    </FormProvider>
  );
};

RegistryForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

const MemoizedRegistryForm = memo(RegistryForm);

export default MemoizedRegistryForm;
