import PropTypes from 'prop-types';
import { ParamsContext } from './useParamsContext';

const ParamsProvider = ({ children, patch, fetchDerivedValues, readOnly = false }) => {
  return <ParamsContext.Provider value={{ patch, fetchDerivedValues, readOnly }}>{children}</ParamsContext.Provider>;
};

ParamsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  patch: PropTypes.func.isRequired,
  fetchDerivedValues: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default ParamsProvider;
