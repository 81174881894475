export default {
  title: "What's new in Sesame 0.2.7 🎉",
  items: [
    {
      title: 'Data Uploads',
      description:
        'Now available for renewable supply curves and grid electricity prices, enabling easier data integration and customization.',
    },
    {
      title: 'Hydrogen System Improvements',
      description:
        'Including enhanced PPA functionality, T&D cost calculations, and improved compression horsepower calculations.',
    },
    {
      title: 'Regional Data Integration',
      description: 'Regional data capability added to key pathway modules, starting with electricity price data.',
    },
    {
      title: 'Coming Soon',
      description: 'Excel model integration for seamless connection with your existing Excel models.',
    },
  ],
};
