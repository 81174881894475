import Decimal from 'decimal.js';
import { CHART_COLORS, CO2_EQUIVALENT, TIME_UNIT_OPTIONS } from '@/consts';
import { unitToString } from './unitUtils';
import useAnalysisStore from '@/stores/analysisStore';

const isElectricity = unit => unit?.species === 'electricity' || unit?.measure === 'power';

const getTimeUnitLabel = timeUnit => {
  const matchingType = TIME_UNIT_OPTIONS.find(type => type.value === timeUnit);
  return matchingType ? matchingType.label : 'Year';
};

const parseWaterfallData = data => {
  let total = 0;

  data.forEach((item, index, arr) => {
    total += item.value;

    const name = typeof item.name === 'string' ? item.name.split(' ') : item.name;
    item.name = name;

    item.backgroundColor = item.backgroundColor ?? '#c3d9fc';

    if (index === 0) {
      item.start = 0;
      item.end = item.value;
    }

    if (index > 0 && index < arr.length - 1) {
      item.start = arr[index - 1].end;
      item.end = item.start + item.value;
    }

    if (index === arr.length - 1) {
      item.start = 0;
      item.end = total;
      item.value = total;
    }

    if (item.value < 0) {
      item.backgroundColor = item.negativeBgColor ?? '#c3d9fc';
    } else {
      item.backgroundColor = item.positiveBgColor ?? '#c3d9fc';
    }
  });

  return data;
};

export const parseTEAGraphData = teaData => {
  const normalizedData = teaData.map(({ label, cost }) => ({
    name: label,
    value: cost,
    negativeBgColor: '#B2F0DC',
    positiveBgColor: '#FFBC71',
  }));

  const totalData = {
    name: 'Total',
    value: 0,
    positiveBgColor: '#E8750F',
  };

  normalizedData.push(totalData);

  return parseWaterfallData(normalizedData);
};

export const parseCashFlowsData = data => {
  if (!data?.cash_flows) {
    return null;
  }

  const cashFlowData = data?.cash_flows;

  return { data: cashFlowData };
};

export const parseTEAData = teaData => {
  return parseTEAGraphData(teaData.levelized_costs);
};

export const parseLCAData = lcaData => {
  const { emissions } = lcaData;
  const data = emissions.map(emission => ({
    name: emission.label,
    value: emission.quantity.scalar,
  }));

  const totalData = {
    name: 'Total',
    value: 0,
    positiveBgColor: '#2d5cf6',
  };

  data.push(totalData);

  return parseWaterfallData(data);
};

const parseBalancedItem = (item, timeUnitLabel) => {
  const { quantity } = item;
  const { scalar, unit } = quantity;

  return {
    ...item,
    quantity: scalar,
    unit: isElectricity(unit) ? unitToString(unit) : `${unitToString(unit)}/${timeUnitLabel.toLocaleLowerCase()}`,
  };
};

const parseExtraItem = item => {
  const { label, quantity } = item;
  const { scalar, unit } = quantity;
  const value = new Decimal(scalar);

  return {
    label,
    quantity: value,
    unit: unitToString(unit),
  };
};

export const parseBalanceData = (data, nodesById, params) => {
  const { node_id, timeframe = 'year' } = params;
  const node = nodesById[node_id];
  const emissions = node.emission_types ?? [];
  const emission = emissions.find(({ name, co2_equivalent }) => {
    return name === params.emission_type && co2_equivalent === params.co2_equivalent;
  });

  const { inputs = [], outputs = [], extra = [], emissions: lcaEmissions } = data;

  const scopes = lcaEmissions.map(({ label, quantity }) => ({
    label: label,
    quantity: { scalar: new Decimal(quantity.scalar).dividedBy(1000), unit: { label: 'ton' } },
  }));

  const timeUnitLabel = getTimeUnitLabel(timeframe);

  let sections = [
    {
      header: 'Feedstocks',
      rows: inputs.map(item => parseBalancedItem(item, timeUnitLabel)),
    },
    {
      header: 'Products',
      rows: outputs.map(item => parseBalancedItem(item, timeUnitLabel)),
    },
    {
      header: emission ? `${emission.label} Emissions` : 'CO₂ Equivalent Emissions',
      rows: scopes.map(item => parseBalancedItem(item, timeUnitLabel)),
    },
  ];

  if (extra.length > 0) {
    sections = [
      {
        header: 'Key Metrics',
        rows: extra.map(item => parseExtraItem(item)),
      },
      ...sections,
    ];
  }

  return sections;
};

const parseCharacteristicsItem = item => {
  const { label, quantity } = item;
  const { scalar, unit } = quantity;
  const value = new Decimal(scalar);

  return {
    label,
    amount: value,
    unit: unitToString(unit),
  };
};

const parseLevelizedCostBreakdownItem = item => {
  const { label, cost, children } = item;
  const value = new Decimal(cost);

  return {
    label,
    amount: value,
    children: children?.map(child => parseLevelizedCostBreakdownItem(child)) ?? [],
  };
};

export const parseCharacteristicsData = data => {
  const { facility_characteristics = [], cost_scaling = [], total_costs = [], financial_results = [] } = data;

  const sections = [
    {
      header: 'Characteristics',
      rows: facility_characteristics?.map(item => parseCharacteristicsItem(item)),
    },
    {
      header: 'Cost Scaling',
      rows: cost_scaling?.map(item => parseCharacteristicsItem(item)),
    },
    {
      header: 'Total Asset Costs',
      rows: total_costs?.map(item => parseCharacteristicsItem(item)),
    },
    {
      header: 'Financial Results',
      rows: financial_results?.map(item => parseCharacteristicsItem(item)),
    },
  ];

  return sections;
};

export const parseCostBreakdownData = data => {
  const { levelized_costs = [] } = data;

  const sections = [
    {
      header: 'Levelized Cost Breakdown',
      rows: levelized_costs?.map(item => parseLevelizedCostBreakdownItem(item)),
      total: levelized_costs?.reduce((total, item) => total.plus(item.cost), new Decimal(0)),
    },
  ];

  return sections;
};

export const parseCharacteristicsFormData = plotFormData => {
  const { nodeId } = plotFormData;

  const params = {
    node_id: nodeId,
  };

  return params;
};

export const parseCostBreakdownFormData = plotFormData => {
  const { nodeId, levelizeBy, levelizeUnit } = plotFormData;
  const params = {
    node_id: nodeId,
    depth: 2,
  };

  if (levelizeUnit) {
    const { io_name, io_type } = JSON.parse(levelizeBy);

    params.levelization = {
      io_name,
      io_type,
      unit: JSON.parse(levelizeUnit),
    };
  }

  return params;
};

export const parseMCData = data => data;

export const getLevelizeByLabel = (node, inputOutput) => {
  const { io_name, io_type } = JSON.parse(inputOutput);
  const output = node[`${io_type}s`].find(io => io.name === io_name);

  return output?.type?.label;
};

export const getLCAYaxisLabel = plot => {
  if (plot.levelize !== 'true') {
    return 'kgCO₂e/year';
  } else {
    const emissionType = plot?.emissionType ? JSON.parse(plot?.emissionType) : {};
    let { label, unit } = emissionType;

    const { levelizeUnit } = plot;
    const unitObj = JSON.parse(levelizeUnit);
    label = label === 'CO₂ Equivalent' ? 'CO₂e' : label;

    return `${unitToString(unit)}${label}/${unitToString(unitObj)}`;
  }
};

export const getTEAYaxisLabel = (plot, node, showLevelizedLabel = true) => {
  const { levelizeUnit, levelizeBy } = plot;
  const unitObj = JSON.parse(levelizeUnit);
  const levelizeLabel = getLevelizeByLabel(node, levelizeBy);

  if (showLevelizedLabel) {
    return `$/${unitToString(unitObj)} ${levelizeLabel}`;
  }

  return `$/${unitToString(unitObj)}`;
};

export const getSensitivityXaxisLabel = (plot, node, type) => {
  const { levelizeUnit, levelizeBy } = plot;
  const unitObj = JSON.parse(levelizeUnit);
  const levelizeLabel = getLevelizeByLabel(node, levelizeBy);
  const label = `${unitToString(unitObj)} ${levelizeLabel}`;

  return type === 'tea' ? `$/${label}` : `kgCO₂e/${label}`;
};

export const getMCXaxisLabel = (distribution, unit, scalar) => {
  if (distribution === 'emissions') {
    return `Levelized Emissions [${unit}]`;
  }

  if (unit.match(/electricity/i) && scalar === 1000) {
    unit = unit.replace('kWh', 'MWh');
  }

  return `Levelized Costs [${unit}]`;
};

export const parseLCAFormData = plotFormData => {
  const { nodeId, levelize, levelizeBy, levelizeUnit, emissionType, scope3Breakdown } = plotFormData;
  const params = {
    node_id: nodeId,
  };

  if (scope3Breakdown) {
    params.scope3_breakdown = scope3Breakdown;
  }

  if (emissionType) {
    const { co2_equivalent, emission_type } = JSON.parse(emissionType);

    params.co2_equivalent = co2_equivalent;

    if (emission_type !== CO2_EQUIVALENT) {
      params.emission_type = emission_type;
    }
  }

  if (levelize === 'true') {
    const { io_name, io_type } = JSON.parse(levelizeBy);

    params.levelization = {
      io_name,
      io_type,
      unit: JSON.parse(levelizeUnit),
    };
  }

  return params;
};

export const parseLevelization = levelization => {
  const { unit, io_type, io_name } = levelization;
  return {
    levelizeUnit: JSON.stringify(unit),
    levelizeBy: JSON.stringify({ io_name, io_type }),
  };
};

export const parseTEAFormData = plotFormData => {
  const { nodeId, levelizeBy, levelizeUnit, upstreamCostsBreakdown } = plotFormData;
  const params = {
    node_id: nodeId,
  };

  params.upstream_costs_breakdown = !!upstreamCostsBreakdown;

  if (levelizeUnit) {
    const { io_name, io_type } = JSON.parse(levelizeBy);

    params.levelization = {
      io_name,
      io_type,
      unit: JSON.parse(levelizeUnit),
    };
  }

  return params;
};

export const parseBalanceFormData = plotFormData => {
  const { nodeId, emissionType, timeframe } = plotFormData;

  const params = {
    node_id: nodeId,
    timeframe,
  };

  if (emissionType) {
    const { emission_type, co2_equivalent } = JSON.parse(emissionType);

    params.co2_equivalent = co2_equivalent;

    if (emission_type !== CO2_EQUIVALENT) {
      params.emission_type = emission_type;
    }
  }

  return params;
};

export const parseMCFormData = plotFormData => {
  const { nodeId, levelize, levelizeBy } = plotFormData;
  const params = {
    node_id: nodeId,
  };

  if (levelize === 'true') {
    const { io_name, io_type } = JSON.parse(levelizeBy);

    params.levelization = {
      io_name,
      io_type,
    };
  }

  return params;
};

export const sortNodesByAnchor = (nodes, anchorId) => {
  return nodes.sort((a, b) => {
    if (a.id === anchorId) return -1;
    if (b.id === anchorId) return 1;
    return 0;
  });
};

export const parseSensitivityData = data => {
  const { sensitivities, baseline } = data;
  const { nodes } = useAnalysisStore.getState();

  if (!sensitivities?.length) {
    return null;
  }

  const labels = sensitivities.map(s => s.param_label);

  const datasets = {};
  let indexColor = 0;

  sensitivities.forEach((s, index) => {
    if (!datasets[`min-${s.node_id}`]) {
      datasets[`min-${s.node_id}`] = {
        label: nodes[s.node_id].label,
        nodeId: s.node_id,
        data: new Array(labels.length).fill(0),
        backgroundColor: CHART_COLORS[indexColor],
        borderColor: CHART_COLORS[indexColor],
        borderWidth: 1,
        customData: new Array(labels.length).fill(0),
      };
    }

    if (!datasets[`max-${s.node_id}`]) {
      datasets[`max-${s.node_id}`] = {
        label: nodes[s.node_id].label,
        nodeId: s.node_id,
        data: new Array(labels.length).fill(0),
        backgroundColor: CHART_COLORS[indexColor],
        borderColor: CHART_COLORS[indexColor],
        borderWidth: 1,
        customData: new Array(labels.length).fill(0),
      };

      indexColor += 1;
    }

    datasets[`max-${s.node_id}`].data[index] = new Decimal(s.max).minus(baseline).toNumber();
    datasets[`min-${s.node_id}`].data[index] = new Decimal(s.min).minus(baseline).toNumber();
    datasets[`max-${s.node_id}`].customData[index] = {
      paramValue: new Decimal(s.max_param_value).toNumber(),
      baselineParamValue: new Decimal(s.baseline_param_value).toNumber(),
    };
    datasets[`min-${s.node_id}`].customData[index] = {
      paramValue: new Decimal(s.min_param_value).toNumber(),
      baselineParamValue: new Decimal(s.baseline_param_value).toNumber(),
    };
  });

  return { datasets: Object.values(datasets), labels, baseline };
};

const parseSensitivityFormData = (formData, type) => {
  const { nodeId, levelizeBy, levelizeUnit } = formData;
  const params = {
    node_id: nodeId,
    analysis_type: type,
  };

  if (levelizeUnit) {
    const { io_name, io_type } = JSON.parse(levelizeBy);

    params.levelization = {
      io_name,
      io_type,
      unit: JSON.parse(levelizeUnit),
    };
  }

  return params;
};

export const parseSensitivityLCAFormData = formData => {
  return parseSensitivityFormData(formData, 'lca');
};

export const parseSensitivityTEAFormData = formData => {
  return parseSensitivityFormData(formData, 'tea');
};
