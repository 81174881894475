import { useIsAnalysisInProgress } from '@/stores/analysisStatusStore';
import { caseActions, useIsBalanced, useIsBalancing } from '@/stores/caseStore';
import { useNodeCount } from '@/stores/pathwayStore';
import { ScaleIcon } from '@heroicons/react/24/outline';

const BalanceButton = () => {
  const isBalanced = useIsBalanced();
  const nodeCount = useNodeCount();
  const isAnalysisInProgress = useIsAnalysisInProgress();
  const isBalancing = useIsBalancing();

  const balancePathway = async () => {
    caseActions.balanceCase();
  };

  if (isBalanced || isAnalysisInProgress) {
    return null;
  }

  if (isBalancing) {
    return (
      <button className="btn btn-sm btn-secondary">
        <span className="loading loading-md"></span>
        Balancing
      </button>
    );
  }

  if (!isBalanced && nodeCount > 0) {
    return (
      <button onClick={balancePathway} className="btn btn-sm btn-primary">
        <ScaleIcon className="w-5 h-5" />
        Balance Pathway
      </button>
    );
  }

  return (
    <button className="btn btn-sm btn-primary btn-disabled">
      <ScaleIcon className="w-5 h-5" />
      Balance Pathway
    </button>
  );
};

export default BalanceButton;
