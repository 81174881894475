import LineChart from '@/components/charts/LineChart';
import { useLCAPlot } from '@/stores/workbenchStore';
import { getLCAYaxisLabel } from '@/utils/workbenchUtils';

const LCAWorkbenchPlot = () => {
  const plot = useLCAPlot();
  const { node, paramLabels, results, workbenchUnits } = plot;

  if (!results) {
    return;
  }

  const legendTitle = paramLabels[paramLabels.length - 1];
  const legendUnit = workbenchUnits[workbenchUnits.length - 1];

  return (
    <LineChart
      legendTitle={`${legendTitle} (${legendUnit})`}
      results={results}
      label={`${paramLabels[0]} - ${node.label}`}
      yAxisLabel={getLCAYaxisLabel(plot, node)}
    />
  );
};

export default LCAWorkbenchPlot;
