import { cssTransition, toast as reactToast } from 'react-toastify';

const NoAnimation = cssTransition({
  enter: 'none',
  exit: 'hidden',
  collapse: true,
});

export const toastOptions = {
  autoClose: false,
  transition: NoAnimation,
  theme: 'colored',
  className: 'relative flex p-1 min-h-10 rounded justify-between overflow-hidden cursor-pointer',
};

export const toast = {
  success: (message, options) => {
    reactToast.clearWaitingQueue();
    reactToast.dismiss();
    reactToast.success(message, { ...toastOptions, ...options });
  },
  error: (message, options) => {
    reactToast.clearWaitingQueue();
    reactToast.dismiss();
    reactToast.error(message, { ...toastOptions, ...options });
  },
  warning: (message, options = { dismiss: true }) => {
    if (options.dismiss) {
      reactToast.clearWaitingQueue();
      reactToast.dismiss();
    }
    reactToast.warning(message, { ...toastOptions, ...options });
  },
};
