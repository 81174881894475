import PropTypes from 'prop-types';

export const DefaultSourceDisplay = ({ source }) => {
  if (!source) return null;
  return <div className="flex text-xs text-gray-400 mr-2">{source}</div>;
};

DefaultSourceDisplay.propTypes = {
  source: PropTypes.string,
};
