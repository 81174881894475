import PropTypes from 'prop-types';
import FormRow from '@/components/core/form/FormRow';
import { useFormContext } from 'react-hook-form';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import usePatchParams from '../usePatchParams';
import { useEffect, useRef } from 'react';
import { isDefined } from '@/utils/miscUtils';
import { NumericFormatInput } from '@/components/core/form/NumericFormatInput';
import { useParamsContext } from '@/components/providers/useParamsContext';

const MCField = ({ param, label, name, type }) => {
  const { value, is_default: isDefault, default: defaultValue } = param ?? {};
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const { readOnly } = useParamsContext();

  const fieldName = `mc_params.${type}.${name}`;
  const error = get(errors, fieldName);
  const errorMessage = error?.message?.[type]?.value;
  const { patchParam } = usePatchParams();
  const numericValueRef = useRef(null);

  const events = {
    onChange: e => {
      e.preventDefault();
    },
    onBlur: async e => {
      const prevValue = getValues(name);
      const value = numericValueRef.current ?? parseFloat(e.target.value);

      if (prevValue !== value) {
        await patchParam(name, type, value, fieldName);
      } else {
        setValue(fieldName, value, { shouldValidate: true, shouldDirty: false });
      }
    },
  };

  useEffect(() => {
    if (isDefined(value)) {
      setValue(fieldName, value, { shouldValidate: true, shouldDirty: false });
    }
  }, [value, fieldName, setValue]);

  return (
    <>
      <FormRow label={label} name={fieldName}>
        <div className="flex flex-col flex-1">
          <div className="join flex">
            <NumericFormatInput
              id={name}
              name={fieldName}
              control={control}
              {...events}
              placeholder={defaultValue?.toString()}
              hasError={!!errorMessage}
              isDefault={isDefault}
              readOnly={readOnly}
              disabled={readOnly}
              onNumericChange={value => {
                numericValueRef.current = value;
              }}
            />
          </div>
          <ErrorMessage text={errorMessage} />
        </div>
      </FormRow>
    </>
  );
};

MCField.propTypes = {
  param: PropTypes.object,
  label: PropTypes.string,
  paramSchema: PropTypes.object,
  type: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
};

export default MCField;
