import { getLevelizeByLabel } from './analysisUtils';
import { numberFormat, unitToString } from './unitUtils';

export const parseResults = data => {
  const results = { labels: [], datasets: [] };
  const datasets = data.series.map((ser, index) => {
    const dataset = { label: ser.value, data: [] };

    ser.points.forEach(([paramValue, resultValue]) => {
      if (index === 0) {
        results.labels.push(numberFormat(paramValue, 3, false));
      }

      dataset.data.push(numberFormat(resultValue, 3, false));
    });

    return dataset;
  });

  results.datasets = datasets;

  return results;
};

export const getParamLabels = (workbenches, paramsByNodeId) => {
  return Array.from(workbenches.values()).map(workbench => {
    const { nodeId, paramName } = workbench;
    return paramsByNodeId?.[nodeId]?.[paramName]?.label;
  });
};

export const getWorkbenchUnits = workbenches => {
  return Array.from(workbenches.values()).map(({ unit }) => unitToString(unit));
};

export const getLCAYaxisLabel = (plot, node) => {
  const { levelizeUnit, levelizeBy } = plot;
  const unitObj = JSON.parse(levelizeUnit);
  const levelizeLabel = getLevelizeByLabel(node, levelizeBy);

  return `kgCO₂e/${unitToString(unitObj)} ${levelizeLabel}`;
};
