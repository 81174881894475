import PropTypes from 'prop-types';
import FormRow from '@/components/core/form/FormRow';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { isDefined } from '@/utils/miscUtils';
import { useDerivedParam } from '@/stores/derivedCacheStore';
import { useDerivedValueFetch } from '@/hooks/useDerivedValueFetch';
import ConversionField from '../ConversionField';
import InputBase from '@/components/core/form/InputBase';
import usePatchParams from '../usePatchParams';

const DerivedField = ({ param, paramSchema, name }) => {
  const { label, type, tooltip, source, convertible_units: convertibleUnits, editable } = paramSchema;
  const { primary: { value } = {} } = param ?? {};
  const { fetch, changed_dependencies: changedDependencies } = value ?? {};
  const { register, setValue, getValues } = useFormContext();
  const registration = register(name, { valueAsNumber: type === 'numeric' });
  const results = useDerivedParam(name);
  const shouldFetch = fetch || !results;
  const currentValue = results?.scalar ?? results ?? '';
  const disabled = !editable;

  const { patchPrimary } = usePatchParams();

  const { isLoading } = useDerivedValueFetch({
    name,
    shouldFetch,
    changedDependencies,
  });

  const events = {
    onChange: () => {},
    onBlur: async e => {
      if (disabled) return;

      const prevValue = getValues(name);
      const value = type === 'numeric' ? parseFloat(e.target.value) : e.target.value;

      if (prevValue !== value) {
        await patchPrimary(name, value);
      } else {
        setValue(name, value, { shouldValidate: true, shouldDirty: false });
      }
    },
  };

  useEffect(() => {
    const value = results?.scalar ?? results;

    if (isDefined(value)) {
      setValue(name, value, { shouldValidate: true, shouldDirty: false });
    }
  }, [results, name, setValue]);

  if (!isDefined(results) && !isLoading) {
    return null;
  }

  return (
    <FormRow label={label} tooltip={tooltip} source={source} name={name}>
      <div className="flex flex-col flex-1">
        <div className="join flex">
          <InputBase id={name} value={currentValue} {...registration} {...events} disabled={disabled} />
          {convertibleUnits?.length > 0 && (
            <ConversionField
              unit={{ value: results?.unit }}
              name={`conversion.${name}`}
              paramName={name}
              units={convertibleUnits}
              disabled
            />
          )}
        </div>
      </div>
    </FormRow>
  );
};

DerivedField.propTypes = {
  param: PropTypes.object,
  paramSchema: PropTypes.object,
  name: PropTypes.string,
  children: PropTypes.node,
};

export default DerivedField;
