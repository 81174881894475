import Tooltip from '@/components/core/Tooltip';
import PropTypes from 'prop-types';
import usePatchParams from '../usePatchParams';

const AutoEligibleField = ({ name, value, readOnly }) => {
  const { patchPrimary } = usePatchParams();

  return (
    <div className="flex gap-2 items-center self-end">
      <input
        type="checkbox"
        disabled={readOnly}
        readOnly={readOnly}
        name={`${name}_auto_eligible`}
        className="toggle toggle-success toggle-xs"
        onChange={e => {
          const value = e.target.checked ? { auto: true } : null;
          patchPrimary(name, value);
        }}
        checked={value === true}
      />
      <div className="flex gap-1 items-center">
        <label htmlFor={`${name}_auto_eligible`} className="text-xs">
          Auto
        </label>
        <Tooltip
          label="Auto"
          text="Enable when a pathway loop/cycle is in place. Overrides this parameter via a solver to ensure balance converges."
        />
      </div>
    </div>
  );
};

AutoEligibleField.propTypes = {
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  value: PropTypes.bool,
};

export default AutoEligibleField;
