import { caseActions, useAnchor, useCostOverridesByNodeAndName, useTempAnchor } from '@/stores/caseStore';
import AnchorForm from './AnchorForm';
import { anchorActions, useFindConversion } from '@/stores/anchorStore';
import { convertFlowRate } from '@/utils/unitUtils';
import { FLOW_RATE_MAP } from '@/consts';
import { useState } from 'react';
import CostOverridesForm from './CostOverridesForm';
import { XMarkIcon } from '@heroicons/react/24/outline';

const AnchorFormDialog = () => {
  const { name, handle, handleType, label, id } = useTempAnchor();
  const costOverride = useCostOverridesByNodeAndName(id, name);
  const [showOverrideForm, setShowOverrideForm] = useState(false);
  const anchor = useAnchor(); // from case
  const value = anchor?.io_name === name ? anchor?.quantity?.scalar : 0;
  const conversion = useFindConversion(handleType, name, anchor?.quantity?.unit);

  const toggleForm = () => {
    setShowOverrideForm(prevState => !prevState);
  };

  const defaultValues = {
    value,
    conversion: JSON.stringify(conversion),
    measure: conversion?.unit?.measure,
  };

  const clear = () => {
    caseActions.clearTempAnchor();
    anchorActions.clear();
  };

  const onSubmit = data => {
    const { conversion, flowRate } = data;
    const { unit } = JSON.parse(conversion);

    let { value } = data;

    // convert back to yearly flow rate
    if (flowRate !== FLOW_RATE_MAP.year) {
      value = convertFlowRate(flowRate, 1, value);
    }

    caseActions.patchAnchor(handle, handleType, value, unit);
    caseActions.clearTempAnchor();
    clear();
  };

  return (
    <dialog className="modal" open>
      <div className="modal-box max-w-sm">
        <div>
          {showOverrideForm ? (
            <div className="transition-all duration-500 ease-in-out transform scale-100 relative">
              <button
                type="button"
                onClick={clear}
                className="absolute -top-4 -right-4 btn btn-ghost btn-sm btn-circle"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
              <h3 className="font-bold text-lg">Override Costs: {label}</h3>
              <CostOverridesForm onToggleForm={toggleForm} onClose={clear} />
            </div>
          ) : (
            <div className="transition-all duration-500 ease-in-out transform scale-100">
              <h3 className="font-bold text-lg">Set Basis: {label}</h3>
              <AnchorForm
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                onCancel={clear}
                ioName={name}
                ioType={handleType}
                onToggleForm={toggleForm}
                costOverride={costOverride}
                showOverrideForm={handleType === 'input'}
              />
            </div>
          )}
        </div>
      </div>
    </dialog>
  );
};

export default AnchorFormDialog;
